import React, {useEffect, useState} from 'react';
import {useMutation, useQueries, useQuery, useQueryClient} from '@tanstack/react-query';

import {Link, useParams, useSearchParams} from 'react-router-dom';
import {Button, Checkbox, Col, Dropdown, MenuProps, notification, Row, Space, Spin, Tooltip} from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import HRTimeGraph from '../../../components/UI/SessionTimeGraph/HRTimeGraph';
import {
  GraphZoomX,
  MarkerItem,
  PeriodItem,
  SessionGeoGraphProps,
  SessionTimeData,
  SessionRRTimeGraph, SessionSpectrTimeGraph, GraphZoomDateX,
} from '../../../components/UI/SessionTimeGraph/types';
import api from '../../../http';
import graphLabelName from '../../../models/helper';
import RRTimeGraph from '../../../components/UI/SessionTimeGraph/RRTimeGraph';
import EnergyExpTimeGraph from '../../../components/UI/SessionTimeGraph/EnergyExpTimeGraph';
import GEOGraph from '../../../components/UI/SessionTimeGraph/GeoGraph';
import {
  apiDateFormatAPITimeTZWithMillisecond,
  appDateFormatWithoutSecond,
  appTimeFormatWithoutSecond,
} from '../../../consts';
import SpectrTimeGraph from '../../../components/UI/SessionTimeGraph/SpectrTimeGraph';
import MarkerTypeEnum from '../../../core/constants/markers';
import ProtocolSessionDialog from '../../../components/smart/ProtocolSessionDialog';
import AddEventDialog from '../../../components/smart/AddEventDialog';
import {
  AddEventDialogData,
  AddEventDialogInitialValues,
} from '../../../components/smart/AddEventDialog/types';
import { EventCreate } from '../../../models/types';
import ECGTimeGraph from "../../../components/UI/SessionTimeGraph/ECGTimeGraph";
import DataZoomGraph from "../../../components/UI/SessionTimeGraph/DataZoomGraph";
import PPGTimeGraph from "../../../components/UI/SessionTimeGraph/PPGTimeGraph";

dayjs.extend(utc);

const currentTimezoneOffsetMinutes = dayjs().utcOffset();
console.log('currentTimezoneOffsetMinutes', currentTimezoneOffsetMinutes);

const Session = () => {
  // координаты графиков - нормированные значения в диапазоне от 0 до 1
  const [graphRangeX, setGraphRangeX] = useState<GraphZoomX>();
  // координаты графиков - по времени - используется только для статистики
  const [graphRangeDateX, setGraphRangeDateX] = useState<GraphZoomDateX>();
  // список всех маркров сессии
  const [markerItems, setMarkersItems] = useState<MarkerItem[]>([])
  // список типов маркеров сессии
  const [markersSet, setMarkersSet] = useState<Record<string, string>>({})
  const [markerIdsVisible, setMarkerIdsVisible] = useState<number[]>([]);
  const [isProtocol, setIsProtocol] = useState(false);
  const [isAddEvent, setIsAddEvent] = useState(false);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [manualEventDate, setManualEventDate] =
    useState<AddEventDialogInitialValues>({
      event_start: new Date(),
      event_end: new Date(),
    });

  const { sessionId } = useParams() as {
    sessionId: string;
  };

  const [searchParams] = useSearchParams();
  // console.log(searchParams);
  const enableGraphRRF = searchParams.has('rr_f')
  const enableGraphHR = searchParams.has('hr')
  const enableGraphSP = searchParams.has('sp')
  const enableGraphExpEnergy = searchParams.has('ex_enrg')
  const enableGraphGEO = searchParams.has('geo')
  // const enableGraphPPG = searchParams.has('ppg')
  const enableGraphPPG = searchParams.has('ppg')

  // ключи useQuery
  const sessionQueryKey = ['session', sessionId]
  const sensorSessionQueryKey = ['sensors_session', sessionId]
  const eventsSessionSessionQueryKey = ['events_session', sessionId]
  const rangeSessionSelectionQueryKey = ['range_session', sessionId, graphRangeDateX]

  const queryClient = useQueryClient();

  const mutationEventCreate = useMutation({
    mutationFn: (data: EventCreate) => api.v1.v1EventsCreateCreate(data),
    onSuccess: () => {
      queryClient.invalidateQueries(eventsSessionSessionQueryKey);
    },
  });

  const {
    isLoading: isLoadingEventsData,
    isError: isErrorEventsData,
    isSuccess: isSuccessEventsData,
    data: eventsData
  } = useQuery(eventsSessionSessionQueryKey, () =>
      api.v1.v1EventsInSessionExtraList(sessionId, {
        marker_type_in: [
          MarkerTypeEnum.User.toString(),
          MarkerTypeEnum.Algorithm.toString(),
          MarkerTypeEnum.External.toString(),
        ].join(","),
      }));

  const {
    data: rangeSelectionData
  } = useQuery(rangeSessionSelectionQueryKey, () =>
    api.v1.v1SessionsRangeRead(sessionId, {
      dt_start: dayjs(graphRangeDateX?.start).toISOString(),
      dt_end: dayjs(graphRangeDateX?.end).toISOString(),
    }));

  const DownloadActions = [
    {
      key: 'xls_measure_filtred',
      label: 'xls - фильтр'
    },
    {
      key: 'xls_measure',
      label: 'xls'
    },
    {
      key: 'xls_measure_ring',
      label: 'Ring xls'
    },
    {
      key: 'xls_expert_stress_ring',
      label: 'Stress ring xls'
    },
    {
      key: 'zip_measure_filtred',
      label: 'ZIP - фильтр',
    },
    {
      key: 'zip_measure',
      label: 'ZIP',
    },
    // {
    //   key: 'xls_measure_filtered_spectr',
    //   label: 'xls - спектр фильтр',
    // },
    // {
    //   key: 'xls_measure_spectr',
    //   label: 'xls - спектр',
    // },
    {
      key: 'csv_measure_filtered_spectr',
      label: 'csv - спектр фильтр',
    },
    {
      key: 'csv_measure_spectr',
      label: 'csv - спектр',
    },
    {
      key: 'zip_device',
      label: 'ZIP с устройства',
    },
    {
      key: 'trainset_stress',
      label: 'TRAINSET с разметкой стрессов',
    },
  ]

  useEffect(()=>{
    console.log('isSuccessEventsData', isSuccessEventsData);

    // if (eventsData){ !!!!!
    // список маркеров сессий
    const markerItemsTmp = eventsData
        ? eventsData?.data.map((item) => ({
          id: item.id,
          marker_id: item.marker,
          sensor: item.sensor,
          start_dt: dayjs(item.start_date).toDate(),
          end_dt: dayjs(item.end_date).toDate(),
          name: item.marker_name ?? '',
          color: item.marker_color ?? '#dcd267',
          notes: item.notes_parsed
        }))
        : []

    // setMarkersItems( markerItemsTmp.filter((item) => ![164, 197].includes(item.marker_id)));
    setMarkersItems( markerItemsTmp);

    // составим уникальный список маркеров сессии
    const markerSetTmp = Object.fromEntries(
        markerItemsTmp?.map((item) => [item.marker_id, item.name]),
    )

    setMarkersSet(markerSetTmp);

  }, [eventsData])

  useEffect(()=>{
    console.log('rangeSelectionData', rangeSelectionData?.data);
  }, [rangeSelectionData])

  const useSessionData = () => {
    const [sessionQuery, sensorsQuery] = useQueries({
      queries: [
        {
          queryKey: sessionQueryKey,
          queryFn: () => api.v1.v1SessionsInfoExtraRead(sessionId),
        },
        {
          queryKey: sensorSessionQueryKey,
          queryFn: () => api.v1.v1SessionsSensorsExtraRead(sessionId),
        },
      ],
    });

    return {
      isLoading:
        sessionQuery.isLoading ||
        sensorsQuery.isLoading,
      isError:
        sessionQuery.isError || sensorsQuery.isError,
      sessionData: sessionQuery.data,
      sensorsData: sensorsQuery.data,
    };
  };

  const { isLoading, isError, sessionData, sensorsData } =
    useSessionData();

  const handleShowDialogAddEvent = (data: AddEventDialogInitialValues) => {
    setManualEventDate(data);
    setIsAddEvent(true);
  };
  const handleOKAddEvent = (data: AddEventDialogData) => {
    setIsAddEvent(false);
    console.log('data', data);
    if (sessionData?.data.carrier) {
      mutationEventCreate.mutate({
        session: +sessionId,
        carrier: sessionData.data.carrier,
        start_date: dayjs(data.event_start).format(
            apiDateFormatAPITimeTZWithMillisecond,
        ),
        end_date: dayjs(data.event_end).format(
            apiDateFormatAPITimeTZWithMillisecond,
        ),
        marker: data.marker,
        notes: data.notes,
      });
    }
  };

  const handleDownloadZIPDevice = async () => {
    try {
      const response = await api.v1.v1SessionsDownloadFileRead(
          sessionId,
          {
            format: 'blob',
          },
      );
      const url = window.URL.createObjectURL(new Blob([response.data as any as string]));
      if (url) {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${sessionId}_session.zip`);
        link.click();
        window.URL.revokeObjectURL(url);
      }
    } catch (err) {
      notification.error({message: 'Проблемы со скачиванием файла'});
    }
  }

  const handleDownloadExcelMeasure = async (filtered: boolean) => {
    try {
      setIsFileLoading(true);
      const response = await api.v1.v1SessionsDownloadMeasureDataXlsxRead(
          sessionId,
          Number(filtered).toString(),
          {timezone_offset: currentTimezoneOffsetMinutes },
          {format: 'blob',}
      );
      const url = window.URL.createObjectURL(new Blob([response.data as any as string]));
      const filterPrefix = (filtered) ? '_filter' : ''
      if (url) {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `session_data_${sessionId}${filterPrefix}.xlsx`);
        link.click();
        window.URL.revokeObjectURL(url);
      }
    } catch (err) {
      notification.error({ message: 'Проблема при создании Excel файла' });
    }
    finally {
      setIsFileLoading(false);
    }
  }

  const handleDownloadExcelMeasureRing = async () => {
    try {
      setIsFileLoading(true);
      const response = await api.v1.v1SessionsDownloadMeasureDataXlsxRingRead(
        sessionId,
        {timezone_offset: currentTimezoneOffsetMinutes },
        {format: 'blob',}
      );
      console.log(response);
      const url = window.URL.createObjectURL(new Blob([response.data as any as string]));
      if (url) {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `HRV.${sessionId}.xlsx`);
        link.click();
        window.URL.revokeObjectURL(url);
      }
    } catch (err) {
      notification.error({ message: 'Проблема при создании Excel файла' });
    }
    finally {
      setIsFileLoading(false);
    }
  }

  const handleDownloadExcelExpertStressRing = async () => {
    try {
      setIsFileLoading(true);
      const response = await api.v1.v1SessionsDownloadExpertStressXlsxRingRead(
        sessionId,
        {timezone_offset: currentTimezoneOffsetMinutes },
        {format: 'blob',}
      );
      console.log(response);
      const url = window.URL.createObjectURL(new Blob([response.data as any as string]));
      if (url) {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Stress.${sessionId}.xlsx`);
        link.click();
        window.URL.revokeObjectURL(url);
      }
    } catch (err) {
      notification.error({ message: 'Проблема при создании Excel файла' });
    }
    finally {
      setIsFileLoading(false);
    }
  }

  const handleDownloadExcelSpectrMeasure = async (filtered: boolean) => {
    try {
      setIsFileLoading(true);
      const response = await api.v1.v1SessionsDownloadMeasureDataRawSpectrXlsxRead(
        sessionId,
        Number(filtered).toString(),
        {timezone_offset: currentTimezoneOffsetMinutes },
        {format: 'blob',}
      );
      const url = window.URL.createObjectURL(new Blob([response.data as any as string]));
      const filterPrefix = (filtered) ? '_filter' : ''
      if (url) {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `session_data_${sessionId}${filterPrefix}_spectr.xlsx`);
        link.click();
        window.URL.revokeObjectURL(url);
      }
    } catch (err) {
      notification.error({ message: 'Проблема при создании Excel файла со спектром' });
    }
    finally {
      setIsFileLoading(false);
    }
  }

  const handleDownloadCSVSpectrMeasure = async (filtered: boolean) => {
    try {
      setIsFileLoading(true);
      const response = await api.v1.v1SessionsDownloadMeasureDataRawSpectrRead(
        sessionId,
        Number(filtered).toString(),
        {timezone_offset: currentTimezoneOffsetMinutes },
        {format: 'blob',}
      );
      const url = window.URL.createObjectURL(new Blob([response.data as any as string]));
      const filterPrefix = (filtered) ? '_filter' : ''
      if (url) {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `session_data_${sessionId}${filterPrefix}_spectr.csv`);
        link.click();
        window.URL.revokeObjectURL(url);
      }
    } catch (err) {
      notification.error({ message: 'Проблема при создании CSV файла со спектром' });
    }
    finally {
      setIsFileLoading(false);
    }
  }

  const handleDownloadZIPMeasure = async (filtered: boolean) => {
    try {
      setIsFileLoading(true);
      // TODO Разобраться со скачиванием файла
      const response = await api.v1.v1SessionsDownloadMeasureDataZipRead(
          sessionId,
          Number(filtered).toString(),
          {timezone_offset: currentTimezoneOffsetMinutes },
          {format: 'blob' }
      );
      const url = window.URL.createObjectURL(new Blob([response.data as any as string]));
      const filterPrefix = (filtered) ? '_filter' : ''
      if (url) {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `session_data_${sessionId}${filterPrefix}.zip`);
        link.click();
        window.URL.revokeObjectURL(url);
      }

    } catch (err) {
      notification.error({ message: 'Проблема при создании ZIP файла' });
    }
    finally {
      setIsFileLoading(false);
    }
  }

  const handleDownloadTrainset = async () => {
    try {
      setIsFileLoading(true);
      // TODO Разобраться со скачиванием файла
      const response = await api.v1.v1SessionsDownloadTrainsetExportRead(
        sessionId,
        {timezone_offset: currentTimezoneOffsetMinutes },
        {format: 'blob' }
      );
      const url = window.URL.createObjectURL(new Blob([response.data as any as string]));
      if (url) {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `trainset_${sessionId}.csv`);
        link.click();
        window.URL.revokeObjectURL(url);
      }

    } catch (err) {
      notification.error({ message: 'Проблема при создании TRAINSET CSV файла' });
    }
    finally {
      setIsFileLoading(false);
    }
  }

  const onDownloadMenuClick: MenuProps['onClick'] = (e) => {
    console.log('click', e.key);
    switch (e.key) {
      case "xls_measure":
        handleDownloadExcelMeasure(false);
        break
      case "xls_measure_filtred":
        handleDownloadExcelMeasure(true);
        break
      case "xls_measure_ring":
        handleDownloadExcelMeasureRing();
        break
      case "xls_expert_stress_ring":
        handleDownloadExcelExpertStressRing();
        break
      case "zip_device":
        handleDownloadZIPDevice();
        break
      case "zip_measure":
        handleDownloadZIPMeasure(false);
        break
      case "zip_measure_filtred":
        handleDownloadZIPMeasure(true);
        break
      case 'xls_measure_spectr':
        handleDownloadExcelSpectrMeasure(false);
        break
      case 'xls_measure_filtered_spectr':
        handleDownloadExcelSpectrMeasure(true);
        break
      case 'csv_measure_spectr':
        handleDownloadCSVSpectrMeasure(false);
        break
      case 'csv_measure_filtered_spectr':
        handleDownloadCSVSpectrMeasure(true);
        break
      case 'trainset_stress':
        handleDownloadTrainset();
        break
      default:
        console.error("Unknown menu")
    }
  };

  if (isError) {
    return <>Произошла ошибка: ${isError}</>;
  }

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 50 }}>
        <Spin />
      </div>
    );
  }

  // пока принимаем такой концепт - 1 сенсор - (1 отображение + его производные) - как раньше на cogni-nn
  // RR = ( RRcalc + RRFilter) , HR = HR, GEO = GEO
  // console.log('Rendering');
  // useEffect(() => {
  //   setCommonMinX(moment(sessionData?.data.start_date).toDate());
  // }, [sessionData]);

  // console.log(graphRangeX);

  const onChangeMarkers = (checkedValues: CheckboxValueType[]) => {
    console.log('checked = ', checkedValues);
    setMarkerIdsVisible(checkedValues.map((item) => +item));
  };

  // useEffect(() => {
  //   setGraphRangeDateX({
  //     start: dayjs(sessionData?.data.start_date).toDate(),
  //     end: dayjs(sessionData?.data.end_date).toDate()
  //   })
  // },[sessionData])

  // отобразим, какие графики будем отображать
  const sessionRRData: SessionRRTimeGraph[] = [];
  // первый проход RR и RR filter идут вместе
  sensorsData?.data
    .filter((item) => item.signal === 5)
    .forEach((item) => {
      const widePeriods: PeriodItem[] = markerItems.filter((it) => it.marker_id === 191 && it.sensor === item.sensor)
          .map((it) => {
            return {
              start_dt: it.start_dt,
              end_dt: it.end_dt,
            };
          });

      sessionRRData.push({
        key: `rr-${item.sensor}`,
        label: graphLabelName(item.signal),
        sensor_name: item.sensor_name,
        minX: dayjs(sessionData?.data.start_date).toDate(),
        maxX: sessionData?.data.end_date
          ? dayjs(sessionData?.data.end_date).toDate()
          : undefined,
        minY: 200,
        maxY: 1500,
        wide_periods: widePeriods,
        markers: markerItems,
        markerIdsVisible,
        showFilteredData: enableGraphRRF,
        queryFn: () =>
          api.v1.v1RrCalcSignalsRead(sessionId, item.sensor.toString()),
        queryFilterFn: () =>
          api.v1.v1RrFilterSignalsRead(sessionId, item.sensor.toString()),
        onCreateEventManual: (data) => {
          handleShowDialogAddEvent(data);
        },
      });
    });

  // второй проход HR идут вместе
  const sessionHRData: SessionTimeData[] = [];
  sensorsData?.data
    .filter((item) => item.signal === 2 && enableGraphHR )
    .forEach((item) => {
      sessionHRData.push({
        key: `hr-${item.sensor}`,
        label: graphLabelName(item.signal),
        minX: dayjs(sessionData?.data.start_date).toDate(),
        maxX: sessionData?.data.end_date
          ? dayjs(sessionData?.data.end_date).toDate()
          : undefined,
        minY: 40,
        maxY: 200,
        queryFn: () =>
          api.v1.v1HrSignalsRead(sessionId,item.sensor.toString()),
        onCreateEventManual: (data) => {
          handleShowDialogAddEvent(data);
        },
      });
    });

  // следующие спектральные данные
  const sessionSpectrData: SessionSpectrTimeGraph[] = [];
  sensorsData?.data
    .filter((item) => item.signal === 7 && enableGraphSP)
    .forEach((item) => {
      sessionSpectrData.push({
        key: `spectr-${item.sensor}`,
        label: graphLabelName(item.signal),
        minX: dayjs(sessionData?.data.start_date).toDate(),
        maxX: sessionData?.data.end_date
          ? dayjs(sessionData?.data.end_date).toDate()
          : undefined,
        minY: 0,
        markers: markerItems,
        markerIdsVisible,
        queryFn: () =>
          api.v1.v1SpectrSignalsRead(
            sessionId, item.sensor.toString(), Number(true).toString(), {stress: true}),
        onCreateEventManual: (data) => {
          handleShowDialogAddEvent(data);
        },
      });
    });

  // потом PPG
  const sessionPPGData: SessionTimeData[] = [];
  sensorsData?.data
    .filter((item) => item.signal === 16 && enableGraphPPG )
    .forEach((item) => {
      sessionPPGData.push({
        key: `ppg-${item.sensor}`,
        label: graphLabelName(item.signal),
        minX: dayjs(sessionData?.data.start_date).toDate(),
        maxX: sessionData?.data.end_date
          ? dayjs(sessionData?.data.end_date).toDate()
          : undefined,
        minY: 40,
        maxY: 200,
        queryFn: () =>
          api.v1.v1PpgSignalsRead(sessionId,item.sensor.toString()),
        onCreateEventManual: (data) => {
          handleShowDialogAddEvent(data);
        },
      });
    });

  // следующие EnergyExp
  const sessionEnergyExpData: SessionTimeData[] = [];
  sensorsData?.data
    .filter((item) => item.signal === 4 && enableGraphExpEnergy)
    .forEach((item) => {
      sessionEnergyExpData.push({
        key: `energy-exp-${item.sensor}`,
        label: graphLabelName(item.signal),
        minX: dayjs(sessionData?.data.start_date).toDate(),
        maxX: sessionData?.data.end_date
          ? dayjs(sessionData?.data.end_date).toDate()
          : undefined,
        // minY: 40,
        // maxY: 200,
        queryFn: () =>
          api.v1.v1EnergyExpendedSignalsRead(sessionId, item.sensor.toString()),
        onCreateEventManual: (data) => {
          handleShowDialogAddEvent(data);
        },
      });
    });

  // следущие ECG данные
  const sessionECGData: SessionTimeData[] = [];
  sensorsData?.data
      .filter((item) => item.signal === 11)
      .forEach((item) => {
        sessionECGData.push({
          key: `ecg-${item.sensor}`,
          label: graphLabelName(item.signal),
          minX: dayjs(sessionData?.data.start_date).toDate(),
          maxX: sessionData?.data.end_date
              ? dayjs(sessionData?.data.end_date).toDate()
              : undefined,
          minY: -16000,
          maxY: 16000,
          queryFn: () =>
              api.v1.v1EcgThinoutSignalsRead(item.sensor.toString(), sessionId),
          onCreateEventManual: (data) => {
            handleShowDialogAddEvent(data);
          },
        });
      });

  // последний идет график GEO
  const sessionGeoData: SessionGeoGraphProps[] = [];
  sensorsData?.data
    .filter((item) => item.signal === 3 && enableGraphGEO)
    .forEach((item) => {
      sessionGeoData.push({
        chartName: 'GEO',
        key: `geo-${item.sensor}`,
        queryFn: api.v1.v1GeoSignalsRead(sessionId),
      });
    });

  return (
    <>
      {/* Заголовок сессии */}
      <Row>
        <Col span={2}>
          Объект: <b>{sessionData?.data.carrier_nick}</b>
        </Col>
        <Col span={2}>
          Контекст: <b>{sessionData?.data.context_name}</b>
        </Col>
        <Col span={3}>
          Дата:{' '}
          <b>
            {dayjs(sessionData?.data.start_date).format(
              appDateFormatWithoutSecond,
            )}
          </b>{' '}
          -{' '}
          <b>
            {dayjs(sessionData?.data.end_date).format(
              appTimeFormatWithoutSecond,
            )}
          </b>
        </Col>
        <Col span={6}>
          Выделение:{' '}<b>
          {graphRangeDateX?.start ? dayjs(graphRangeDateX?.start).format(appTimeFormatWithoutSecond) : ''}{' '}
          -{' '}
          {graphRangeDateX?.end ? dayjs(graphRangeDateX?.end).format(appTimeFormatWithoutSecond) : ''}{', '}</b>
          {graphRangeDateX?.start && graphRangeDateX?.end && <b>D: {
            dayjs(graphRangeDateX?.end).diff(dayjs(graphRangeDateX?.start), 'second')
          } с</b>}
          {rangeSelectionData && <div>
              N=<b>{rangeSelectionData.data.rr_stats?.count}</b>,
              P_ред=<b>{rangeSelectionData.data.rr_stats?.p_edit} %</b>,
              ЭС=<b>{rangeSelectionData.data.extrasystole_count}</b>,
              Spike=<b>{rangeSelectionData.data.spike_count}</b>
          </div>}
        </Col>
        <Col>
          <Space>
            <Tooltip title="Отчет по событиям в сессии (исходные данные)">
              <Link
                to={`/telemetry/sessions_report/${sessionId}/source`}
                target="_blank"
              >
                <Button>Отчет</Button>
              </Link>
            </Tooltip>
            <Tooltip title="Отчет по событиям в сессии (фильтрованные данные)">
              <Link
                to={`/telemetry/sessions_report/${sessionId}/filtered`}
                target="_blank"
              >
                <Button>Отчет фильтр</Button>
              </Link>
            </Tooltip>
            <Tooltip title="Список событий в сессии">
              <Button
                onClick={() => {
                  setIsProtocol(true);
                }}
              >
                Протокол
              </Button>
            </Tooltip>
            <Spin size="large" spinning={isFileLoading}/>
            <Dropdown.Button
                menu={{ items:DownloadActions, onClick: onDownloadMenuClick }}
            >Скачать</Dropdown.Button>
            {/* <Tooltip title="Скачивание данных в формате trainset - только экстрасистолы для Ковальчука">
              <LineHeightOutlined onClick={async () => {
                try {
                  // TODO Разобраться со скачиванием файла
                  const response = await api.v1.v1SessionsDownloadTrainsetExtrasystoleRead(sessionId, {
                    format: 'blob',
                  });
                  const url = window.URL.createObjectURL(new Blob([response.data as any as string]));
                  if (url) {
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute(
                        'download',
                        `${sessionData?.data.carrier_nick}_${sessionId}_trainset_extrasystole.csv`);
                    link.click();
                    window.URL.revokeObjectURL(url);
                  }

                } catch (err) {
                  notification.error({ message: 'Проблема при создании файла Trainset с экстрасистолами' });
                }
              }}
              />
            </Tooltip> */}
          </Space>
        </Col>
      </Row>
      {/* Список событий */}
      <Row>
        <Col span={1}>Маркеры:</Col>
        <Col span={23}>
          <Checkbox.Group
            options={Object.entries(markersSet).map((item) => {
              return { value: item[0], label: item[1] };
            })}
            onChange={onChangeMarkers}
          />
        </Col>
      </Row>
      {/* Вывод RR графиков */}
      <Row>
        <Col span={24}>
          {sessionRRData.length > 0 &&
              <DataZoomGraph
                  timeData={sessionRRData[0]}
                  key='overview'
                  chartName='scale'
                  graphRangeX={graphRangeX}
                  setGraphRangeX={setGraphRangeX}
                  graphRangeDateX={graphRangeDateX}
                  setGraphRangeDateX={setGraphRangeDateX}
              />
          }
          {sessionRRData.map((item, index) => {
            return (
              <RRTimeGraph
                timeData={item}
                key={item.key}
                chartName={item.key}
                graphRangeX={graphRangeX}
              />
            );
          })}
        </Col>
      </Row>
      {/* Вывод ЧСС графиков */}
      <Row>
        <Col span={24}>
          {sessionHRData.length > 0}
          {sessionHRData.map((item) => {
            return (
              <HRTimeGraph
                timeData={item}
                key={item.key}
                chartName={item.key}
                graphRangeX={graphRangeX}
              />
            );
          })}
        </Col>
      </Row>
      {/* Вывод спектральных графиков */}
      <Row>
        <Col span={24}>
          {sessionSpectrData.length > 0}
          {sessionSpectrData.map((item) => {
            return (
              <SpectrTimeGraph
                timeData={item}
                key={item.key}
                chartName={item.key}
                graphRangeX={graphRangeX}
              />
            );
          })}
        </Col>
      </Row>
      {/* Вывод PPG графиков */}
      <Row>
        <Col span={24}>
          {sessionPPGData.length > 0}
          {sessionPPGData.map((item) => {
            return (
              <PPGTimeGraph
                timeData={item}
                key={item.key}
                chartName={item.key}
                graphRangeX={graphRangeX}
              />
            );
          })}
        </Col>
      </Row>
      {/* Вывод графиков потраченной энергии */}
      <Row>
        <Col span={24}>
          {sessionEnergyExpData.length > 0 && <h4>Energy</h4>}
          {sessionEnergyExpData.map((item) => {
            return (
              <EnergyExpTimeGraph
                timeData={item}
                key={item.key}
                chartName={item.key}
                graphRangeX={graphRangeX}
              />
            );
          })}
        </Col>
      </Row>
      {/* Вывод ECG графиков */}
      <Row>
        <Col span={24}>
          {sessionECGData.length > 0 && <h4>ECG</h4>}
          {sessionECGData.map((item) => {
            return (
                <ECGTimeGraph
                    timeData={item}
                    key={item.key}
                    chartName={item.key}
                    graphRangeX={graphRangeX}
                />
            );
          })}
        </Col>
      </Row>
      {/* Вывод GEO координат */}
      <Row>
        <Col span={24}>
          {sessionGeoData.length > 0 && <h4>GEO</h4>}
          {sessionGeoData.map((item) => {
            return (
              <GEOGraph
                key={item.key}
                chartName={item.chartName}
                queryFn={item.queryFn}
              />
            );
          })}
        </Col>
      </Row>
      {/* Диалог вывода протокола - фильтранем тут лишнее} */}
      <ProtocolSessionDialog
        queryKeyInvalidate={eventsSessionSessionQueryKey}
        isShow={isProtocol}
        markerItems={markerItems.filter((item) => ![164, 197, 204].includes(item.marker_id))}
        onSubmit={() => setIsProtocol(false)}
      />
      {/* Диалог создания события} */}
      <AddEventDialog
        initialValues={manualEventDate}
        isShow={isAddEvent}
        onSubmit={handleOKAddEvent}
        onCancel={() => {
          setIsAddEvent(false);
        }}
      />
    </>
  );
};

export default Session;

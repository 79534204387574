/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface Email {
  /**
   * Email
   * @format email
   * @minLength 1
   */
  email: string;
}

export interface PasswordToken {
  /**
   * Пароль
   * @minLength 1
   */
  password: string;
  /**
   * Token
   * @minLength 1
   */
  token: string;
}

export interface ResetToken {
  /**
   * Token
   * @minLength 1
   */
  token: string;
}

export interface MobileTokenCheck {
  /**
   * Username
   * @minLength 1
   */
  username: string;
  /**
   * Код регистрации мобильного телефона
   * @minLength 1
   */
  code: string;
  /**
   * Идентификатор мобильного телефона
   * @minLength 1
   */
  uid: string;
}

export interface TokenCreate {
  /**
   * Ключ доступа
   * @minLength 1
   * @maxLength 30
   */
  key?: string | null;
}

export interface MobileTokenCreate {
  /**
   * Username
   * @minLength 1
   */
  username: string;
  /**
   * Password
   * @minLength 1
   */
  password: string;
  /**
   * Идентификатор мобильного телефона
   * @minLength 1
   */
  uid: string;
  /**
   * Информация о телефоне
   * @minLength 1
   */
  info: string;
}

export interface MobileTokenObtainPair {
  /**
   * Код устройства
   * @minLength 1
   */
  token: string;
  /** Идентификатор мобильного телефона */
  uid: string;
}

export interface JWTTokens {
  /**
   * Refresh токен
   * @minLength 1
   */
  refresh: string;
  /**
   * Аccess токен
   * @minLength 1
   */
  access: string;
}

export interface SiteTokenObtainPair {
  /**
   * Username
   * @minLength 1
   */
  username: string;
  /**
   * Password
   * @minLength 1
   */
  password: string;
}

export interface TokenRefresh {
  /**
   * Refresh
   * @minLength 1
   */
  refresh: string;
  /**
   * Access
   * @minLength 1
   */
  access?: string;
}

export interface TokenVerify {
  /**
   * Token
   * @minLength 1
   */
  token: string;
}

export interface BatteryChargeCreate {
  /**
   * Время измерения уровня заряда батареи
   * @format date-time
   */
  dt: string;
  /** Значение уровня заряда батареи */
  value: number;
  /** Идентификатор измерительной сессии */
  session: number;
  /** Идентификатор измерительного сенсора */
  sensor: number;
}

export interface BatteryCharge {
  /** Идентификатор данных */
  id?: number;
  /**
   * Время измерения уровня заряда батареи
   * @format date-time
   */
  dt: string;
  /** Значение уровня заряда батареи */
  value: number;
}

export interface Carrier {
  /** ID */
  id?: number;
  /**
   * Ник
   * @minLength 1
   * @maxLength 100
   */
  nick: string;
  /**
   * Дата рождения
   * @format date
   */
  date_birth: string;
  /** Пол */
  gender: 1 | 2;
}

export interface SessionContext {
  /** ID */
  id?: number;
  /**
   * Имя контекста
   * @minLength 1
   * @maxLength 255
   */
  name: string;
}

export interface SessionContextNameExtra {
  names: string[];
}

export interface SessionContextExtra {
  /** ID */
  id?: number;
  /**
   * Имя контекста
   * @minLength 1
   * @maxLength 255
   */
  name: string;
  /**
   * Expert name
   * @minLength 1
   */
  expert_name?: string;
}

export interface CommonResult {
  /**
   * Текстовое пояснение статуса результата
   * @minLength 1
   */
  detail?: string;
}

export interface ECGDataMeasure {
  /** Идентификатор данных */
  id: number;
  /**
   * Время измерения отсчета
   * @format date-time
   */
  dt: string;
  /** Значение отсчета */
  value: number;
}

export interface EnergyExpendedData {
  /**
   * Время измерения энергии интервала
   * @format date-time
   */
  dt: string;
  /** Энергия в килоджоулях */
  energy: number;
}

export interface EnergyExpendedBlock {
  energy_data: EnergyExpendedData[];
  /** Идентификатор измерительного сенсора */
  sensor: number;
  /** Идентификатор измерительной сессии */
  session: number;
}

export interface EnergyExpendedDataMeasure {
  /** Идентификатор данных */
  id: number;
  /**
   * Время измерения энергии интервала
   * @format date-time
   */
  dt: string;
  /** Энергия в килоджоулях */
  value: number;
}

export interface EventClose {
  /** Идентификатор события */
  id: number;
  /** Идентификатор носителя */
  carrier: number;
  /** Идентификатор маркера */
  marker: number;
  /**
   * Время окончания события
   * @format date-time
   */
  end_date: string;
  /**
   * Комментарий
   * @minLength 1
   * @maxLength 10000
   */
  notes?: string;
}

export interface EventCreate {
  /** ID */
  id?: number;
  /** Носитель, от имени которого ставят событие */
  carrier: number;
  /**
   * Дата начала
   * @format date-time
   */
  start_date: string;
  /**
   * Дата окончания
   * @format date-time
   */
  end_date?: string | null;
  /** Marker */
  marker: number;
  /** Session */
  session?: number | null;
  /**
   * Комментарий
   * @minLength 1
   * @maxLength 10000
   */
  notes?: string | null;
  /** Сенсор, от которого произошло событие */
  sensor?: number | null;
}

export interface Event {
  /** ID */
  id?: number;
  /** Носитель, от имени которого ставят событие */
  carrier: number;
  /**
   * Дата начала
   * @format date-time
   */
  start_date: string;
  /**
   * Дата окончания
   * @format date-time
   */
  end_date?: string | null;
  /** Marker */
  marker: number;
  /** Marker type */
  marker_type?: number;
  /** Session */
  session?: number | null;
  /**
   * Комментарий
   * @minLength 1
   * @maxLength 10000
   */
  notes?: string | null;
  /** Сенсор, от которого произошло событие */
  sensor?: number | null;
}

export interface EventExtra {
  /** ID */
  id?: number;
  /** Носитель, от имени которого ставят событие */
  carrier: number;
  /**
   * Дата начала
   * @format date-time
   */
  start_date: string;
  /**
   * Дата окончания
   * @format date-time
   */
  end_date?: string | null;
  /** Marker */
  marker: number;
  /** Marker type */
  marker_type?: number;
  /**
   * Marker name
   * @minLength 1
   */
  marker_name?: string;
  /**
   * Marker color
   * @minLength 1
   */
  marker_color?: string;
  /** Session */
  session?: number | null;
  /** Сенсор, от которого произошло событие */
  sensor?: number | null;
  /** Обработанный комментарий */
  notes_parsed?: string;
}

export interface EventsReport {
  /** Event */
  event: number;
  /** Идентификатор события */
  event_id?: number;
  /**
   * Название события
   * @minLength 1
   */
  event_name?: string;
  /** Длительность события в секундах */
  duration_sec?: number;
  /** Среднее RR(исходное) */
  rr?: number | null;
  /** Среднее RR(фильтрованное) */
  rr_filter?: number | null;
  /** Среднее отклонение RR(исходное) */
  rr_div?: number | null;
  /** Ошибка среднего RR(фильтрованное) */
  rr_filter_div?: number | null;
  /** Среднее ЧСС */
  hr?: number | null;
  /**
   * Мода RR интервалов, ms
   * @min -2147483648
   * @max 2147483647
   */
  mo?: number | null;
  /**
   * Мода RR интервалов, ms (фильтрованное)
   * @min -2147483648
   * @max 2147483647
   */
  mo_filter?: number | null;
  /** Срeднее LF(исходное) */
  LF?: number | null;
  /** Срeднее LF(фильтрованное) */
  LF_filter?: number | null;
  /** Среднее HF(исходное) */
  HF?: number | null;
  /** Среднее HF(фильтрованное) */
  HF_filter?: number | null;
  /** Среднее TP(исходное) */
  TP?: number | null;
  /** Среднее TP(фильтрованное) */
  TP_filter?: number | null;
  /** Индекс вегетативного баланса(исходное) */
  ivb?: number | null;
  /** Индекс вегетативного баланса(фильтрованное) */
  ivb_filter?: number | null;
  /** Среднее SDNN(исходное) */
  sdnn?: number | null;
  /** Среднее SDNN(фильтрованное) */
  sdnn_filter?: number | null;
  /** УОС в % */
  yoc?: number | null;
  /**
   * Количество экстрасистол
   * @min -2147483648
   * @max 2147483647
   */
  extrasystoles?: number | null;
  /**
   * Количество спайков
   * @min -2147483648
   * @max 2147483647
   */
  spikes?: number | null;
  /** Доля артефактов в записи */
  P_edit?: number | null;
  /** PNN50 */
  pNN50?: number | null;
  /** PNN50 (фильтрованное) */
  pNN50_filter?: number | null;
  /** Физиологическая норма */
  physio_norm?: number | null;
  /** Физиологическая норма (фильтрованное) */
  physio_norm_filter?: number | null;
  /** Донозологические условия */
  prenos_cond?: number | null;
  /** Донозологические условия (фильтрованное) */
  prenos_cond_filter?: number | null;
  /** Преморбидные состояния */
  premor_cond?: number | null;
  /** Преморбидные состояния (фильтрованное) */
  premor_cond_filter?: number | null;
  /** Патологические состояния */
  pathol_cond?: number | null;
  /** Патологические состояния (фильтрованное) */
  pathol_cond_filter?: number | null;
  /** Q редактируемое в % */
  Q_edit?: number | null;
  /** Q редактируемое в % (фильтрованное) */
  Q_edit_filter?: number | null;
  /** Стресс индекс */
  SI?: number | null;
  /** Стресс индекс (фильтрованное) */
  SI_filter?: number | null;
  /** Мода */
  M0?: number | null;
  /** Мода (фильтрованное) */
  M0_filter?: number | null;
  /** Амплитуда моды */
  AM0?: number | null;
  /** Амплитуда моды (фильтрованное) */
  AM0_filter?: number | null;
  /** Вариационный размах */
  MxDMn?: number | null;
  /** Вариационный размах (фильтрованное) */
  MxDMn_filter?: number | null;
  /** Степень HF в процентах */
  HF_per?: number | null;
  /** Степень HF в процентах (фильтрованное) */
  HF_per_filter?: number | null;
  /** Функциональный резерв */
  FR?: number | null;
  /** Функциональный резерв (фильтрованное) */
  FR_filter?: number | null;
  /** Степень напряжения */
  SN?: number | null;
  /** Степень напряжения (фильтрованное) */
  SN_filter?: number | null;
  /** Уровень адаптационных рисков */
  adap_risk?: number | null;
  /** Уровень адаптационных рисков (фильтрованное) */
  adap_risk_filter?: number | null;
  /**
   * Статус расчета события
   * @min 0
   * @max 9223372036854776000
   */
  status?: number | null;
}

export interface Expert {
  /** ID */
  id?: number;
  /**
   * Имя пользователя
   * Обязательное поле. Не более 150 символов. Только буквы, цифры и символы @/./+/-/_.
   * @minLength 1
   * @maxLength 150
   * @pattern ^[\w.@+-]+$
   */
  username: string;
  /**
   * Адрес электронной почты
   * @format email
   * @maxLength 254
   */
  email?: string;
  /**
   * Дата рождения
   * @format date
   */
  date_birth: string;
  /** Пол */
  gender: 1 | 2;
  /**
   * Отчество
   * @maxLength 150
   */
  middle_name?: string | null;
  /** Email верифицирован */
  is_email_verified?: boolean;
  /** Информация о себе */
  about?: string | null;
  /**
   * Активный
   * Отметьте, если пользователь должен считаться активным. Уберите эту отметку вместо удаления учётной записи.
   */
  is_active?: boolean;
  /**
   * Дата регистрации
   * @format date-time
   */
  date_joined?: string;
}

export interface FRSNData {
  /**
   * Время расчета FRSN интервала
   * @format date-time
   */
  dt: string;
  /** Cреднее значение ЧСС */
  hr_medium: number;
  /** Cтресс индекс */
  SI: number;
  /** Мода */
  M0: number;
  /** Амплитуда моды */
  AM0: number;
  /** Вариационный размах */
  MxDMn: number;
  /** pNN50 */
  pNN50: number;
  /** Степень HF в процентах */
  HF_per: number;
  /** Функциональный резерв */
  FR: number;
  /** Степень напряжения */
  SN: number;
}

export interface GEOData {
  /**
   * Время измерения GEO координаты
   * @format date-time
   */
  dt: string;
  /** Широта */
  latitude: number;
  /** Долгота */
  longitude: number;
  /** Высота */
  elevation: number;
}

export interface GEOBlock {
  geo_data: GEOData[];
  /** Идентификатор измерительной сессии */
  session: number;
}

export interface GEODataMeasure {
  /** Идентификатор данных */
  id: number;
  /**
   * Время измерения GEO координаты
   * @format date-time
   */
  dt: string;
  /** Широта */
  latitude: number;
  /** Долгота */
  longitude: number;
  /** Высота */
  elevation: number;
}

export interface HRData {
  /**
   * Время измерения ЧСС
   * @format date-time
   */
  dt: string;
  /** Значение ЧСС */
  hr: number;
}

export interface HRBlock {
  hr_data: HRData[];
  /** Идентификатор измерительной сессии */
  session: number;
  /** Идентификатор измерительного сенсора */
  sensor: number;
}

export interface HRDataMeasure {
  /** Идентификатор данных */
  id: number;
  /**
   * Время измерения ЧСС
   * @format date-time
   */
  dt: string;
  /** Значение ЧСС */
  value: number;
}

export interface LogInformation {
  /** ID */
  id?: number;
  /**
   * Время создания лога
   * @format date-time
   */
  created: string;
  /** Пользователь приславший сообщение */
  user?: number | null;
  /**
   * User name
   * @minLength 1
   */
  user_name?: string;
  /**
   * Название модуля. Например, имя приславшей программы.
   * @minLength 1
   * @maxLength 200
   */
  module: string;
  /**
   * Уровень логирования от 1 -DEBUG, 2 - INFO, 3 - WARNING, 4 - ERROR, 5 - CRITICAL
   * @min -32768
   * @max 32767
   */
  level: number;
  /**
   * Сообщение
   * @minLength 1
   */
  message: string;
}

export interface Marker {
  /** ID */
  id?: number;
  /**
   * Имя маркера
   * @minLength 1
   * @maxLength 200
   */
  name: string;
}

export interface MarkerExtra {
  /** ID */
  id?: number;
  /**
   * Имя маркера
   * @minLength 1
   * @maxLength 200
   */
  name: string;
  /**
   * Цвет маркера
   * @minLength 1
   * @maxLength 7
   */
  color?: string;
}

export interface MarkerExtraUpdate {
  /** ID */
  id?: number;
  /**
   * Цвет маркера
   * @minLength 1
   * @maxLength 7
   */
  color?: string;
}

export interface SessionContextMarker {
  /** ID */
  id: number;
  /** Marker */
  marker: number;
  /**
   * Marker name
   * @minLength 1
   */
  marker_name?: string;
  /**
   * Номер маркера в контексте
   * @min -32768
   * @max 32767
   */
  show_num: number;
}

export interface MarkersInContext {
  markers: SessionContextMarker[];
}

export interface SessionContextMarkerCreate {
  /** ID */
  id?: number;
  /** Marker */
  marker: number;
  /**
   * Marker name
   * @minLength 1
   */
  marker_name?: string;
  /**
   * Номер маркера в контексте
   * @min -32768
   * @max 32767
   */
  show_num: number;
}

export interface MeasuringDevice {
  /** ID */
  id?: number;
  /** User */
  user: number;
  /**
   * Серийный номер датчика
   * @minLength 1
   * @maxLength 100
   */
  serial: string;
  /**
   * Идентификатор телефона
   * @minLength 1
   * @maxLength 30
   */
  phone_uid: string;
  /**
   * Тип устройства
   * @minLength 1
   * @maxLength 100
   */
  device_type: string;
  /** Активация устройства */
  is_activate?: boolean;
}

export interface MeasuringDeviceCreate {
  /** User */
  user?: number;
  /**
   * Серийный номер датчика
   * @minLength 1
   * @maxLength 100
   */
  serial: string;
  /**
   * Идентификатор телефона
   * @minLength 1
   * @maxLength 30
   */
  phone_uid: string;
  /**
   * Тип устройства
   * @minLength 1
   * @maxLength 100
   */
  device_type: string;
}

export interface MeasuringDeviceActivate {
  /** User */
  user?: number;
  /**
   * Серийный номер датчика
   * @minLength 1
   * @maxLength 100
   */
  serial: string;
  /**
   * Идентификатор телефона
   * @minLength 1
   * @maxLength 30
   */
  phone_uid: string;
  /**
   * Тип устройства
   * @minLength 1
   * @maxLength 100
   */
  device_type: string;
  /**
   * Временный код доступа
   * @minLength 1
   * @maxLength 10
   */
  temporary_code?: string | null;
}

export interface MeasuringDeviceActivateResult {
  /** Флаг активации */
  activated: boolean;
}

export interface PPGData {
  /**
   * Время измерения PPG
   * @format date-time
   */
  dt: string;
  /** green */
  green: number;
  /** red */
  red: number;
  /** infrared */
  ir: number;
}

export interface PPGBlock {
  ppg_data: PPGData[];
  /** Идентификатор измерительной сессии */
  session: number;
  /** Идентификатор измерительного сенсора */
  sensor: number;
}

export interface PPGDataMeasure {
  /** Идентификатор данных */
  id: number;
  /**
   * Время измерения PPG координаты
   * @format date-time
   */
  dt: string;
  /** green */
  g: number;
  /** red */
  r: number;
  /** infrared */
  i: number;
}

export interface Token {
  /** ID */
  id?: number;
  /**
   * Created
   * @format date-time
   */
  created?: string;
  /**
   * Modified
   * @format date-time
   */
  modified?: string;
  /**
   * Ключ доступа
   * @minLength 1
   * @maxLength 30
   */
  key?: string | null;
  /**
   * Временный код доступа
   * @minLength 1
   * @maxLength 10
   */
  temporary_code?: string | null;
  /**
   * Идентификатор телефона
   * @minLength 1
   * @maxLength 30
   */
  phone_uid?: string | null;
  /** Активация идентификатора */
  is_activate?: boolean;
  /**
   * Информация о телефоне
   * @minLength 1
   * @maxLength 300
   */
  info: string;
  /** User */
  user: number;
}

export interface TrustedEmails {
  /** ID */
  id?: number;
  /**
   * Created
   * @format date-time
   */
  created?: string;
  /**
   * Modified
   * @format date-time
   */
  modified?: string;
  /**
   * Информация о персоне
   * @minLength 1
   * @maxLength 255
   */
  info?: string | null;
  /**
   * Доверенные Email
   * @format email
   * @minLength 1
   * @maxLength 254
   */
  email: string;
}

export interface UserSerializerCreate {
  /** ID */
  id?: number;
  /**
   * Имя пользователя
   * Обязательное поле. Не более 150 символов. Только буквы, цифры и символы @/./+/-/_.
   * @minLength 1
   * @maxLength 150
   * @pattern ^[\w.@+-]+$
   */
  username: string;
  /**
   * Дата рождения
   * @format date
   */
  date_birth: string;
  /** Пол */
  gender: 1 | 2;
  /**
   * Адрес электронной почты
   * @format email
   * @maxLength 254
   */
  email?: string;
  /**
   * Пароль
   * @minLength 1
   * @maxLength 128
   */
  password: string;
  /** Информация о себе */
  about?: string | null;
  /**
   * Code
   * @minLength 1
   */
  code: string;
}

export interface ProgramSection {
  /** ID */
  id?: number;
  /**
   * Метка раздела
   * @minLength 1
   * @maxLength 100
   */
  label: string;
  /**
   * Название раздела
   * @minLength 1
   * @maxLength 200
   */
  name: string;
  /**
   * Описание раздела
   * @minLength 1
   * @maxLength 500
   */
  description: string;
  /**
   * Порядок отображения
   * @min -32768
   * @max 32767
   */
  order?: number;
}

export interface ProgramVersion {
  /** ID */
  id?: number;
  /** Идентификатор носителя */
  section: number;
  /**
   * Название файла
   * @minLength 1
   * @maxLength 200
   */
  filename: string;
  /**
   * Версия программы
   * @minLength 1
   * @maxLength 15
   */
  version: string;
  /**
   * Изменения
   * @maxLength 2000
   */
  changelog?: string;
  /** Актуальная версия */
  actual?: boolean;
  /** URL файла */
  url?: string;
}

export interface ProgramVersionSerializerPartial {
  /** ID */
  id?: number;
  /** Актуальная версия */
  actual?: boolean;
}

export interface RRDataMeasureCalc {
  /** Идентификатор данных */
  id: number;
  /**
   * Время измерения RR интервала
   * @format date-time
   */
  dt: string;
  /** Значение RR интервала */
  value: number;
}

export interface RRData {
  /**
   * Время измерения RR интервала
   * @format date-time
   */
  dt: string;
  /** Значение RR интервала */
  rr: number;
  /** Последовательно нарастающий в сессии счетчик */
  ts: number;
}

export interface RRBlock {
  rr_data: RRData[];
  /** Идентификатор измерительного сенсора */
  sensor: number;
  /** Идентификатор измерительной сессии */
  session: number;
}

export interface RRDataMeasure {
  /** Идентификатор данных */
  id: number;
  /**
   * Время измерения RR интервала
   * @format date-time
   */
  dt: string;
  /** Значение RR интервала */
  value: number;
  /** Последовательно нарастающий в сессии счетчик */
  ts: number;
}

export interface Sensor {
  /** ID */
  id?: number;
  /** Носитель, которого измеряют с этим датчиком */
  carrier: number;
  /** Тип датчика */
  sensor_type: 1 | 2 | 3 | 4;
  /**
   * Адрес сенсора
   * @minLength 1
   * @maxLength 100
   */
  address: string;
  /**
   * Название датчика от производителя
   * @minLength 1
   * @maxLength 100
   */
  manufacturer_name: string;
  /**
   * Название датчика данное пользователем
   * @minLength 1
   * @maxLength 100
   */
  name?: string;
  /**
   * Серийный номер
   * @minLength 1
   * @maxLength 100
   */
  serial_number?: string | null;
}

export interface SensorExtra {
  /** ID */
  id?: number;
  /** Носитель, которого измеряют с этим датчиком */
  carrier: number;
  /**
   * Nick
   * @minLength 1
   */
  nick?: string;
  /** Тип датчика */
  sensor_type: 1 | 2 | 3 | 4;
  /**
   * Адрес сенсора
   * @minLength 1
   * @maxLength 100
   */
  address: string;
  /**
   * Название датчика от производителя
   * @minLength 1
   * @maxLength 100
   */
  manufacturer_name: string;
  /**
   * Название датчика данное пользователем
   * @minLength 1
   * @maxLength 100
   */
  name?: string;
  /**
   * Серийный номер
   * @minLength 1
   * @maxLength 100
   */
  serial_number?: string | null;
  /**
   * Parent
   * @minLength 1
   */
  parent?: string;
}

export interface SessionFinish {
  /** Идентификатор сессии */
  id: number;
  /**
   * Время окончания сессии
   * @format date-time
   */
  end_date: string;
}

export interface SessionInfo {
  /** ID */
  id?: number;
  /** Идентификатор носителя */
  carrier: number;
  /**
   * Дата начала
   * @format date-time
   */
  start_date: string;
  /**
   * Дата окончания
   * @format date-time
   */
  end_date?: string | null;
  /** Идентификатор контекста */
  context: number;
  /** Статус измерения */
  status?: '1' | '2' | '3' | '4' | null;
  /**
   * Имя сессии
   * @maxLength 100
   */
  name?: string | null;
  /** Описание сессии */
  description?: string | null;
}

export interface SessionInfoExtra {
  /** ID */
  id?: number;
  /** Идентификатор носителя */
  carrier: number;
  /**
   * Carrier nick
   * @minLength 1
   */
  carrier_nick?: string;
  /**
   * Дата начала
   * @format date-time
   */
  start_date: string;
  /**
   * Дата окончания
   * @format date-time
   */
  end_date?: string | null;
  /** Идентификатор контекста */
  context: number;
  /**
   * Context name
   * @minLength 1
   */
  context_name?: string;
  /** Статус измерения */
  status?: '1' | '2' | '3' | '4' | null;
  /**
   * Имя сессии
   * @maxLength 100
   */
  name?: string | null;
  /** Описание сессии */
  description?: string | null;
}

export interface Session {
  /** ID */
  id?: number;
  /** Идентификатор носителя */
  carrier: number;
  /**
   * Дата начала
   * @format date-time
   */
  start_date: string;
  /**
   * Дата окончания
   * @format date-time
   */
  end_date?: string | null;
  /** Идентификатор контекста */
  context: number;
  /** Статус измерения */
  status?: '1' | '2' | '3' | '4' | null;
}

export interface SessionFrontend {
  /** ID */
  id?: number;
  /** Идентификатор носителя */
  carrier: number;
  /**
   * Nick
   * @minLength 1
   */
  nick?: string;
  /**
   * Gender
   * @minLength 1
   */
  gender?: string;
  /**
   * Parent name
   * @minLength 1
   */
  parent_name?: string;
  /**
   * Дата начала
   * @format date-time
   */
  start_date: string;
  /**
   * Дата окончания
   * @format date-time
   */
  end_date?: string | null;
  /** Идентификатор контекста */
  context: number;
  /**
   * Context name
   * @minLength 1
   */
  context_name?: string;
  /** Статус измерения */
  status?: '1' | '2' | '3' | '4' | null;
  /**
   * Возраст носителя на момент эксперимента
   * @min -32768
   * @max 32767
   */
  carrier_old?: number | null;
}

export interface RRStats {
  /** Количество RR точек */
  count: number;
  /** Качество RR сигнала в % */
  p_edit: number;
}

export interface SessionRangeState {
  /** ID */
  id?: number;
  /** Количество спайков */
  spike_count?: number;
  /** Количество экстрасистол */
  extrasystole_count?: number;
  rr_stats?: RRStats;
}

export interface SessionSignal {
  /** ID */
  id?: number;
  /** Сигнал */
  signal: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16;
  /**
   * Идентификатор данных
   * @min -2147483648
   * @max 2147483647
   */
  data_id?: number;
  /**
   * Информация о сигнале
   * @minLength 1
   * @maxLength 2000
   */
  info?: string | null;
  /** Session */
  session: number;
  /** Sensor */
  sensor: number;
}

export interface SessionSignalExtra {
  /** ID */
  id?: number;
  /** Session */
  session: number;
  /** Сигнал */
  signal: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16;
  /** Sensor */
  sensor: number;
  /**
   * Sensor name
   * @minLength 1
   */
  sensor_name?: string;
}

export interface SessionCreate {
  /** ID */
  id?: number;
  /** Идентификатор носителя */
  carrier: number;
  /**
   * Дата начала
   * @format date-time
   */
  start_date: string;
  /** Идентификатор контекста */
  context: number;
  /**
   * Идентификатор сессии на cogninn
   * @min -2147483648
   * @max 2147483647
   */
  old_id?: number | null;
}

export interface SessionState {
  /** ID */
  id?: number;
  /**
   * Дата начала
   * @format date-time
   */
  start_date: string;
  /**
   * Дата окончания
   * @format date-time
   */
  end_date?: string | null;
  /** Статус измерения */
  status?: '1' | '2' | '3' | '4' | null;
  /** Количество спайков */
  spike_count?: number;
  /** Количество экстрасистол */
  extrasystole_count?: number;
  /** Последняя точка ФР */
  last_fr?: number | null;
  /** Последняя точка СН */
  last_sn?: number | null;
}

export interface SessionUpdate {
  /** ID */
  id: number;
  /**
   * Имя сессии
   * @maxLength 100
   */
  name?: string | null;
  /** Описание сессии */
  description?: string | null;
}

export interface SpectrData {
  /**
   * Время расчета спектра интервала
   * @format date-time
   */
  dt: string;
  /** Ультра низкочастотная компонента спектра */
  vlf: number;
  /** Низкочастотная компонента спектра */
  lf: number;
  /** Ультра низкочастотная компонента */
  hf: number;
}

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || 'http://127.0.0.1:8000/api' });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Cognet API
 * @version v1
 * @baseUrl http://127.0.0.1:8000/api
 * @contact <eugenevc@gmail.com>
 *
 * Описание API для сервиса событийно-связанной телеметрии CogNET
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  passwordReset = {
    /**
     * @description Sends a signal reset_password_token_created when a reset token was created
     *
     * @tags password_reset
     * @name PasswordResetCreate
     * @summary An Api View which provides a method to request a password reset token based on an e-mail address
     * @request POST:/password_reset/
     * @secure
     */
    passwordResetCreate: (data: Email, params: RequestParams = {}) =>
      this.request<Email, any>({
        path: `/password_reset/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description An Api View which provides a method to reset a password based on a unique token
     *
     * @tags password_reset
     * @name PasswordResetConfirmCreate
     * @request POST:/password_reset/confirm/
     * @secure
     */
    passwordResetConfirmCreate: (data: PasswordToken, params: RequestParams = {}) =>
      this.request<PasswordToken, any>({
        path: `/password_reset/confirm/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description An Api View which provides a method to verify that a token is valid
     *
     * @tags password_reset
     * @name PasswordResetValidateTokenCreate
     * @request POST:/password_reset/validate_token/
     * @secure
     */
    passwordResetValidateTokenCreate: (data: ResetToken, params: RequestParams = {}) =>
      this.request<ResetToken, any>({
        path: `/password_reset/validate_token/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  token = {
    /**
     * @description Создание или получение носителя
     *
     * @tags token
     * @name TokenMobileCheckTokenCreate
     * @request POST:/token/mobile_check_token/
     * @secure
     */
    tokenMobileCheckTokenCreate: (data: MobileTokenCheck, params: RequestParams = {}) =>
      this.request<TokenCreate, void>({
        path: `/token/mobile_check_token/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Создание или получение носителя
     *
     * @tags token
     * @name TokenMobileCreateTokenCreate
     * @request POST:/token/mobile_create_token/
     * @secure
     */
    tokenMobileCreateTokenCreate: (data: MobileTokenCreate, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/token/mobile_create_token/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Получение Access и Refresh JWT токенов по паре токен-UID телефона
     *
     * @tags token
     * @name TokenMobileObtainCreate
     * @request POST:/token/mobile_obtain/
     * @secure
     */
    tokenMobileObtainCreate: (data: MobileTokenObtainPair, params: RequestParams = {}) =>
      this.request<JWTTokens, void>({
        path: `/token/mobile_obtain/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Получение Access и Refresh JWT токенов по паре логин-пароль
     *
     * @tags token
     * @name TokenObtainCreate
     * @request POST:/token/obtain/
     * @secure
     */
    tokenObtainCreate: (data: SiteTokenObtainPair, params: RequestParams = {}) =>
      this.request<JWTTokens, void>({
        path: `/token/obtain/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
     *
     * @tags token
     * @name TokenRefreshCreate
     * @request POST:/token/refresh/
     * @secure
     */
    tokenRefreshCreate: (data: TokenRefresh, params: RequestParams = {}) =>
      this.request<TokenRefresh, any>({
        path: `/token/refresh/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Takes a token and indicates if it is valid.  This view provides no information about a token's fitness for a particular use.
     *
     * @tags token
     * @name TokenVerifyCreate
     * @request POST:/token/verify/
     * @secure
     */
    tokenVerifyCreate: (data: TokenVerify, params: RequestParams = {}) =>
      this.request<TokenVerify, any>({
        path: `/token/verify/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  v1 = {
    /**
     * @description Создание измерения заряда батареи
     *
     * @tags Данные: Заряд батареи
     * @name V1BatteryChargeCreate
     * @request POST:/v1/battery_charge/
     * @secure
     */
    v1BatteryChargeCreate: (data: BatteryChargeCreate, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/v1/battery_charge/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Получение батарейных данных в сессии
     *
     * @tags Данные: Заряд батареи
     * @name V1BatteryChargeRead
     * @request GET:/v1/battery_charge/{session_id}/{sensor_id}/
     * @secure
     */
    v1BatteryChargeRead: (sessionId: string, sensorId: string, params: RequestParams = {}) =>
      this.request<BatteryCharge[], void>({
        path: `/v1/battery_charge/${sessionId}/${sensorId}/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Создание или получение носителя
     *
     * @tags Носители
     * @name V1CarriersCreateCreate
     * @request POST:/v1/carriers/create
     * @secure
     */
    v1CarriersCreateCreate: (data: Carrier, params: RequestParams = {}) =>
      this.request<Carrier, void>({
        path: `/v1/carriers/create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Получение списка носителей
     *
     * @tags Носители
     * @name V1CarriersListList
     * @request GET:/v1/carriers/list
     * @secure
     */
    v1CarriersListList: (params: RequestParams = {}) =>
      this.request<Carrier[], void>({
        path: `/v1/carriers/list`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Получение списка контекстов текущего эксперта
     *
     * @tags Контексты
     * @name V1ContextsList
     * @request GET:/v1/contexts/
     * @secure
     */
    v1ContextsList: (params: RequestParams = {}) =>
      this.request<SessionContext[], void>({
        path: `/v1/contexts/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Создание контекста экспертом
     *
     * @tags Контексты
     * @name V1ContextsCreate
     * @request POST:/v1/contexts/
     * @secure
     */
    v1ContextsCreate: (data: SessionContext, params: RequestParams = {}) =>
      this.request<SessionContext, void>({
        path: `/v1/contexts/`,
        method: 'POST',
        body: data,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Получение списка наименований контекстов для сайта
     *
     * @tags Контексты
     * @name V1ContextsListContextNamesExtra
     * @request GET:/v1/contexts/list_context_names_extra/
     * @secure
     */
    v1ContextsListContextNamesExtra: (params: RequestParams = {}) =>
      this.request<SessionContextNameExtra, void>({
        path: `/v1/contexts/list_context_names_extra/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Получение списка контекстов для сайта
     *
     * @tags Контексты
     * @name V1ContextsListExtra
     * @request GET:/v1/contexts/list_extra/
     * @secure
     */
    v1ContextsListExtra: (params: RequestParams = {}) =>
      this.request<SessionContextExtra[], void>({
        path: `/v1/contexts/list_extra/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Получение информации по конкретному контексту
     *
     * @tags Контексты
     * @name V1ContextsRead
     * @request GET:/v1/contexts/{id}/
     * @secure
     */
    v1ContextsRead: (id: string, params: RequestParams = {}) =>
      this.request<SessionContext, void>({
        path: `/v1/contexts/${id}/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Обновление информации о контексте
     *
     * @tags Контексты
     * @name V1ContextsPartialUpdate
     * @request PATCH:/v1/contexts/{id}/
     * @secure
     */
    v1ContextsPartialUpdate: (id: string, data: SessionContext, params: RequestParams = {}) =>
      this.request<CommonResult, void>({
        path: `/v1/contexts/${id}/`,
        method: 'PATCH',
        body: data,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Удаление контекста
     *
     * @tags Контексты
     * @name V1ContextsDelete
     * @request DELETE:/v1/contexts/{id}/
     * @secure
     */
    v1ContextsDelete: (id: string, params: RequestParams = {}) =>
      this.request<CommonResult, void>({
        path: `/v1/contexts/${id}/`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Получение данных о ECG в сессии
     *
     * @tags Данные: ECG
     * @name V1EcgSignalsRead
     * @request GET:/v1/ecg_signals/{session_id}/{sensor_id}/
     * @secure
     */
    v1EcgSignalsRead: (sessionId: string, sensorId: string, params: RequestParams = {}) =>
      this.request<ECGDataMeasure[], void>({
        path: `/v1/ecg_signals/${sessionId}/${sensorId}/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Получение автоматически прореженных данных о ECG в сессии
     *
     * @tags Данные: ECG
     * @name V1EcgThinoutSignalsRead
     * @request GET:/v1/ecg_thinout_signals/{session_id}/{sensor_id}/
     * @secure
     */
    v1EcgThinoutSignalsRead: (sessionId: string, sensorId: string, params: RequestParams = {}) =>
      this.request<ECGDataMeasure[], void>({
        path: `/v1/ecg_thinout_signals/${sessionId}/${sensorId}/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Создание потраченной энергии
     *
     * @tags Данные: потраченная энергия
     * @name V1EnergyExpendedSignalsCreate
     * @request POST:/v1/energy_expended_signals/
     * @secure
     */
    v1EnergyExpendedSignalsCreate: (data: EnergyExpendedBlock, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/v1/energy_expended_signals/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Получение данных о потраченной энергии в сессии
     *
     * @tags Данные: потраченная энергия
     * @name V1EnergyExpendedSignalsRead
     * @request GET:/v1/energy_expended_signals/{session_id}/{sensor_id}/
     * @secure
     */
    v1EnergyExpendedSignalsRead: (sessionId: string, sensorId: string, params: RequestParams = {}) =>
      this.request<EnergyExpendedDataMeasure[], void>({
        path: `/v1/energy_expended_signals/${sessionId}/${sensorId}/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Закрытие события
     *
     * @tags События
     * @name V1EventsCloseCreate
     * @request POST:/v1/events/close
     * @secure
     */
    v1EventsCloseCreate: (data: EventClose, params: RequestParams = {}) =>
      this.request<EventCreate, void>({
        path: `/v1/events/close`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Создание события
     *
     * @tags События
     * @name V1EventsCreateCreate
     * @request POST:/v1/events/create
     * @secure
     */
    v1EventsCreateCreate: (data: EventCreate, params: RequestParams = {}) =>
      this.request<EventCreate, void>({
        path: `/v1/events/create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Удаление события
     *
     * @tags События
     * @name V1EventsDeleteRead
     * @request GET:/v1/events/delete/{event_id}/
     * @secure
     */
    v1EventsDeleteRead: (eventId: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/v1/events/delete/${eventId}/`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Перерасчет события
     *
     * @tags События
     * @name V1EventsRecalculateRead
     * @request GET:/v1/events/recalculate/{event_id}/
     * @secure
     */
    v1EventsRecalculateRead: (eventId: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/v1/events/recalculate/${eventId}/`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Получение событий в сессии. Если session_id=0, то получим не привязанные сессии.
     *
     * @tags События
     * @name V1EventsInSessionList
     * @request GET:/v1/events_in_session/{session_id}/
     * @secure
     */
    v1EventsInSessionList: (
      sessionId: string,
      query?: {
        /** Список маркеров */
        marker_in?: string;
        /** Список маркеров */
        marker_type_in?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<Event[], void>({
        path: `/v1/events_in_session/${sessionId}/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Расширенный метод получение событий в сессии.
     *
     * @tags События
     * @name V1EventsInSessionExtraList
     * @request GET:/v1/events_in_session_extra/{session_id}/
     * @secure
     */
    v1EventsInSessionExtraList: (
      sessionId: string,
      query?: {
        /** Список маркеров */
        marker_in?: string;
        /** Список маркеров */
        marker_type_in?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<EventExtra[], void>({
        path: `/v1/events_in_session_extra/${sessionId}/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Получение отчета по событиям
     *
     * @tags Отчеты
     * @name V1EventsReportRead
     * @request GET:/v1/events_report/{session_id}/
     * @secure
     */
    v1EventsReportRead: (sessionId: string, params: RequestParams = {}) =>
      this.request<EventsReport[], void>({
        path: `/v1/events_report/${sessionId}/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Класс работы с экспертом
     *
     * @tags Аккаунт
     * @name V1ExpertsList
     * @request GET:/v1/experts/
     * @secure
     */
    v1ExpertsList: (params: RequestParams = {}) =>
      this.request<Expert[], any>({
        path: `/v1/experts/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Класс работы с экспертом
     *
     * @tags Аккаунт
     * @name V1ExpertsCreate
     * @request POST:/v1/experts/
     * @secure
     */
    v1ExpertsCreate: (data: Expert, params: RequestParams = {}) =>
      this.request<Expert, any>({
        path: `/v1/experts/`,
        method: 'POST',
        body: data,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Класс работы с экспертом
     *
     * @tags Аккаунт
     * @name V1ExpertsRead
     * @request GET:/v1/experts/{id}/
     * @secure
     */
    v1ExpertsRead: (id: number, params: RequestParams = {}) =>
      this.request<Expert, any>({
        path: `/v1/experts/${id}/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Класс работы с экспертом
     *
     * @tags Аккаунт
     * @name V1ExpertsUpdate
     * @request PUT:/v1/experts/{id}/
     * @secure
     */
    v1ExpertsUpdate: (id: number, data: Expert, params: RequestParams = {}) =>
      this.request<Expert, any>({
        path: `/v1/experts/${id}/`,
        method: 'PUT',
        body: data,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Класс работы с экспертом
     *
     * @tags Аккаунт
     * @name V1ExpertsPartialUpdate
     * @request PATCH:/v1/experts/{id}/
     * @secure
     */
    v1ExpertsPartialUpdate: (id: number, data: Expert, params: RequestParams = {}) =>
      this.request<Expert, any>({
        path: `/v1/experts/${id}/`,
        method: 'PATCH',
        body: data,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Класс работы с экспертом
     *
     * @tags Аккаунт
     * @name V1ExpertsDelete
     * @request DELETE:/v1/experts/{id}/
     * @secure
     */
    v1ExpertsDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/v1/experts/${id}/`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Получение последней расчетной точки функционального резерва и степени напряжения
     *
     * @tags Данные: Функциональные резервы и степень напряжения
     * @name V1FrsnLastPointRead
     * @request GET:/v1/frsn_last_point/{session_id}/{sensor_id}/
     * @secure
     */
    v1FrsnLastPointRead: (
      sessionId: string,
      sensorId: string,
      query?: {
        /**
         * Фильтрованный сигнал
         * @default true
         */
        filtered?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<FRSNData, void>({
        path: `/v1/frsn_last_point/${sessionId}/${sensorId}/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Получение функционального резерва и степени напряжения
     *
     * @tags Данные: Функциональные резервы и степень напряжения
     * @name V1FrsnSignalsRead
     * @request GET:/v1/frsn_signals/{session_id}/{sensor_id}/
     * @secure
     */
    v1FrsnSignalsRead: (
      sessionId: string,
      sensorId: string,
      query?: {
        /**
         * Фильтрованный сигнал
         * @default true
         */
        filtered?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<FRSNData[], void>({
        path: `/v1/frsn_signals/${sessionId}/${sensorId}/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Создание GEO координаты
     *
     * @tags Данные: GEO
     * @name V1GeoSignalsCreate
     * @request POST:/v1/geo_signals/
     * @secure
     */
    v1GeoSignalsCreate: (data: GEOBlock, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/v1/geo_signals/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Получение GEO данных сессии
     *
     * @tags Данные: GEO
     * @name V1GeoSignalsRead
     * @request GET:/v1/geo_signals/{session_id}/
     * @secure
     */
    v1GeoSignalsRead: (sessionId: string, params: RequestParams = {}) =>
      this.request<GEODataMeasure[], void>({
        path: `/v1/geo_signals/${sessionId}/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Проверка сервера на работоспособность
     *
     * @tags Системные
     * @name V1HealthList
     * @request GET:/v1/health
     */
    v1HealthList: (params: RequestParams = {}) =>
      this.request<
        {
          /** Системное время сервера */
          system_time?: string;
        },
        any
      >({
        path: `/v1/health`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Создание ЧСС измерения
     *
     * @tags Данные: ЧСС
     * @name V1HrSignalsCreate
     * @request POST:/v1/hr_signals/
     * @secure
     */
    v1HrSignalsCreate: (data: HRBlock, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/v1/hr_signals/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Получение ЧСС данных сессии
     *
     * @tags Данные: ЧСС
     * @name V1HrSignalsRead
     * @request GET:/v1/hr_signals/{session_id}/{sensor_id}/
     * @secure
     */
    v1HrSignalsRead: (sessionId: string, sensorId: string, params: RequestParams = {}) =>
      this.request<HRDataMeasure[], void>({
        path: `/v1/hr_signals/${sessionId}/${sensorId}/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Получение информационных сообщений
     *
     * @tags v1
     * @name V1LogInformationList
     * @request GET:/v1/log_information/
     * @secure
     */
    v1LogInformationList: (
      query?: {
        /** module */
        module?: string;
        /** module__contains */
        module__contains?: string;
        /** Сортировка */
        order?: 'id' | '-id' | 'created' | '-created' | 'level' | '-level';
        /** Number of results to return per page. */
        limit?: number;
        /** The initial index from which to return the results. */
        offset?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          count: number;
          /** @format uri */
          next?: string | null;
          /** @format uri */
          previous?: string | null;
          results: LogInformation[];
        },
        any
      >({
        path: `/v1/log_information/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Создание информационного сообщения
     *
     * @tags v1
     * @name V1LogInformationCreate
     * @request POST:/v1/log_information/
     * @secure
     */
    v1LogInformationCreate: (data: LogInformation, params: RequestParams = {}) =>
      this.request<LogInformation, any>({
        path: `/v1/log_information/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Получение информационного сообщения
     *
     * @tags v1
     * @name V1LogInformationRead
     * @request GET:/v1/log_information/{id}/
     * @secure
     */
    v1LogInformationRead: (id: number, params: RequestParams = {}) =>
      this.request<LogInformation, any>({
        path: `/v1/log_information/${id}/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Удаление информационного сообщения
     *
     * @tags v1
     * @name V1LogInformationDelete
     * @request DELETE:/v1/log_information/{id}/
     * @secure
     */
    v1LogInformationDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/v1/log_information/${id}/`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Получение списка маркеров
     *
     * @tags Маркеры
     * @name V1MarkersList
     * @request GET:/v1/markers/
     * @secure
     */
    v1MarkersList: (
      query?: {
        /** Тип маркера, используется для фильтрации */
        event_type?: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<Marker[], void>({
        path: `/v1/markers/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Получение расширенного полного списка маркеров
     *
     * @tags Маркеры
     * @name V1MarkersListExtra
     * @request GET:/v1/markers/list_extra/
     * @secure
     */
    v1MarkersListExtra: (
      query?: {
        /** Тип маркера, используется для фильтрации */
        event_type?: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<MarkerExtra[], void>({
        path: `/v1/markers/list_extra/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Обновление цвета маркера
     *
     * @tags Маркеры
     * @name V1MarkersPartialUpdate
     * @request PATCH:/v1/markers/{id}/
     * @secure
     */
    v1MarkersPartialUpdate: (id: number, data: MarkerExtraUpdate, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/v1/markers/${id}/`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Получение списка маркеров в контексте
     *
     * @tags Маркеры
     * @name V1MarkersInContextList
     * @request GET:/v1/markers_in_context/{context_id}/
     * @secure
     */
    v1MarkersInContextList: (contextId: string, params: RequestParams = {}) =>
      this.request<MarkersInContext, void>({
        path: `/v1/markers_in_context/${contextId}/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Создание маркера в контексте
     *
     * @tags Маркеры
     * @name V1MarkersInContextCreate
     * @request POST:/v1/markers_in_context/{context_id}/
     * @secure
     */
    v1MarkersInContextCreate: (contextId: string, data: SessionContextMarkerCreate, params: RequestParams = {}) =>
      this.request<CommonResult, void>({
        path: `/v1/markers_in_context/${contextId}/`,
        method: 'POST',
        body: data,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Обновление маркера в сессии
     *
     * @tags Маркеры
     * @name V1MarkersInContextPartialUpdate
     * @request PATCH:/v1/markers_in_context/{context_id}/{id}/
     * @secure
     */
    v1MarkersInContextPartialUpdate: (
      contextId: string,
      id: string,
      data: SessionContextMarkerCreate,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/v1/markers_in_context/${contextId}/${id}/`,
        method: 'PATCH',
        body: data,
        secure: true,
        ...params,
      }),

    /**
     * @description Удаление маркера из сессии
     *
     * @tags Маркеры
     * @name V1MarkersInContextDelete
     * @request DELETE:/v1/markers_in_context/{context_id}/{id}/
     * @secure
     */
    v1MarkersInContextDelete: (contextId: string, id: string, params: RequestParams = {}) =>
      this.request<CommonResult, void>({
        path: `/v1/markers_in_context/${contextId}/${id}/`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Класс для работы с измерительными устройствами
     *
     * @tags Аккаунт
     * @name V1MeasuringDeviceList
     * @request GET:/v1/measuring_device/
     * @secure
     */
    v1MeasuringDeviceList: (params: RequestParams = {}) =>
      this.request<MeasuringDevice[], any>({
        path: `/v1/measuring_device/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Класс для работы с измерительными устройствами
     *
     * @tags Аккаунт
     * @name V1MeasuringDeviceCreate
     * @request POST:/v1/measuring_device/
     * @secure
     */
    v1MeasuringDeviceCreate: (data: MeasuringDeviceCreate, params: RequestParams = {}) =>
      this.request<MeasuringDeviceCreate, any>({
        path: `/v1/measuring_device/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Активация измерительного устройства по коду устройства
     *
     * @tags Аккаунт
     * @name V1MeasuringDeviceActivate
     * @request POST:/v1/measuring_device/activate/
     * @secure
     */
    v1MeasuringDeviceActivate: (data: MeasuringDeviceActivate, params: RequestParams = {}) =>
      this.request<MeasuringDeviceActivateResult, void>({
        path: `/v1/measuring_device/activate/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Класс для работы с измерительными устройствами
     *
     * @tags Аккаунт
     * @name V1MeasuringDeviceDelete
     * @request DELETE:/v1/measuring_device/{id}/
     * @secure
     */
    v1MeasuringDeviceDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/v1/measuring_device/${id}/`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Проверка сервера на доступность
     *
     * @tags Системные
     * @name V1PingList
     * @request GET:/v1/ping
     */
    v1PingList: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/v1/ping`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Создание измерения фотоплетизмограммы
     *
     * @tags Данные: PPG
     * @name V1PpgSignalsCreate
     * @request POST:/v1/ppg_signals/
     * @secure
     */
    v1PpgSignalsCreate: (data: PPGBlock, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/v1/ppg_signals/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Получение PPG данных сессии
     *
     * @tags Данные: PPG
     * @name V1PpgSignalsRead
     * @request GET:/v1/ppg_signals/{session_id}/{sensor_id}/
     * @secure
     */
    v1PpgSignalsRead: (sessionId: string, sensorId: string, params: RequestParams = {}) =>
      this.request<PPGDataMeasure[], void>({
        path: `/v1/ppg_signals/${sessionId}/${sensorId}/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Отсылка кода авторизации
     *
     * @tags Аккаунт
     * @name V1ProfileSendCodeRead
     * @request GET:/v1/profile/send_code/{email}/
     * @secure
     */
    v1ProfileSendCodeRead: (email: string, params: RequestParams = {}) =>
      this.request<CommonResult, any>({
        path: `/v1/profile/send_code/${email}/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Получение списка токенов текущего пользователя
     *
     * @tags Аккаунт
     * @name V1ProfileTokensList
     * @request GET:/v1/profile/tokens/
     * @secure
     */
    v1ProfileTokensList: (params: RequestParams = {}) =>
      this.request<Token[], void>({
        path: `/v1/profile/tokens/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Создание токена доступа
     *
     * @tags Аккаунт
     * @name V1ProfileTokensCreate
     * @request POST:/v1/profile/tokens/
     * @secure
     */
    v1ProfileTokensCreate: (data: TokenCreate, params: RequestParams = {}) =>
      this.request<Token, void>({
        path: `/v1/profile/tokens/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Получение токена доступа
     *
     * @tags Аккаунт
     * @name V1ProfileTokensRead
     * @request GET:/v1/profile/tokens/{id}/
     * @secure
     */
    v1ProfileTokensRead: (id: number, params: RequestParams = {}) =>
      this.request<Token, void>({
        path: `/v1/profile/tokens/${id}/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Обновление токена доступа
     *
     * @tags Аккаунт
     * @name V1ProfileTokensPartialUpdate
     * @request PATCH:/v1/profile/tokens/{id}/
     * @secure
     */
    v1ProfileTokensPartialUpdate: (id: number, data: Token, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/v1/profile/tokens/${id}/`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Удаление токена доступа
     *
     * @tags Аккаунт
     * @name V1ProfileTokensDelete
     * @request DELETE:/v1/profile/tokens/{id}/
     * @secure
     */
    v1ProfileTokensDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/v1/profile/tokens/${id}/`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Активация токена доступа
     *
     * @tags Аккаунт
     * @name V1ProfileTokensActivate
     * @request GET:/v1/profile/tokens/{id}/activate/
     * @secure
     */
    v1ProfileTokensActivate: (
      id: number,
      query?: {
        /** Access токен для активации аккаунта */
        token?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CommonResult, void>({
        path: `/v1/profile/tokens/${id}/activate/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Посылка письма с активацией токена
     *
     * @tags Аккаунт
     * @name V1ProfileTokensSendActivateEmail
     * @request GET:/v1/profile/tokens/{id}/send_activate_email/
     * @secure
     */
    v1ProfileTokensSendActivateEmail: (id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/v1/profile/tokens/${id}/send_activate_email/`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Вьюха для доверенных emails
     *
     * @tags Аккаунт
     * @name V1ProfileTrustedEmailsList
     * @request GET:/v1/profile/trusted_emails/
     * @secure
     */
    v1ProfileTrustedEmailsList: (params: RequestParams = {}) =>
      this.request<TrustedEmails[], any>({
        path: `/v1/profile/trusted_emails/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Вьюха для доверенных emails
     *
     * @tags Аккаунт
     * @name V1ProfileTrustedEmailsCreate
     * @request POST:/v1/profile/trusted_emails/
     * @secure
     */
    v1ProfileTrustedEmailsCreate: (data: TrustedEmails, params: RequestParams = {}) =>
      this.request<TrustedEmails, any>({
        path: `/v1/profile/trusted_emails/`,
        method: 'POST',
        body: data,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Вьюха для доверенных emails
     *
     * @tags Аккаунт
     * @name V1ProfileTrustedEmailsRead
     * @request GET:/v1/profile/trusted_emails/{id}/
     * @secure
     */
    v1ProfileTrustedEmailsRead: (id: number, params: RequestParams = {}) =>
      this.request<TrustedEmails, any>({
        path: `/v1/profile/trusted_emails/${id}/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Вьюха для доверенных emails
     *
     * @tags Аккаунт
     * @name V1ProfileTrustedEmailsUpdate
     * @request PUT:/v1/profile/trusted_emails/{id}/
     * @secure
     */
    v1ProfileTrustedEmailsUpdate: (id: number, data: TrustedEmails, params: RequestParams = {}) =>
      this.request<TrustedEmails, any>({
        path: `/v1/profile/trusted_emails/${id}/`,
        method: 'PUT',
        body: data,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Вьюха для доверенных emails
     *
     * @tags Аккаунт
     * @name V1ProfileTrustedEmailsPartialUpdate
     * @request PATCH:/v1/profile/trusted_emails/{id}/
     * @secure
     */
    v1ProfileTrustedEmailsPartialUpdate: (id: number, data: TrustedEmails, params: RequestParams = {}) =>
      this.request<TrustedEmails, any>({
        path: `/v1/profile/trusted_emails/${id}/`,
        method: 'PATCH',
        body: data,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Вьюха для доверенных emails
     *
     * @tags Аккаунт
     * @name V1ProfileTrustedEmailsDelete
     * @request DELETE:/v1/profile/trusted_emails/{id}/
     * @secure
     */
    v1ProfileTrustedEmailsDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/v1/profile/trusted_emails/${id}/`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Создание пользователя программы
     *
     * @tags Аккаунт
     * @name V1ProfileUsersCreate
     * @request POST:/v1/profile/users/
     * @secure
     */
    v1ProfileUsersCreate: (data: UserSerializerCreate, params: RequestParams = {}) =>
      this.request<CommonResult, void>({
        path: `/v1/profile/users/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Получение списка программных секций
     *
     * @tags Программное обеспечение
     * @name V1ProgramsSectionList
     * @request GET:/v1/programs_section/
     * @secure
     */
    v1ProgramsSectionList: (
      query?: {
        /** Название раздела, используется для фильтрации */
        name?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ProgramSection[], void>({
        path: `/v1/programs_section/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Создание раздела для программ
     *
     * @tags Программное обеспечение
     * @name V1ProgramsSectionCreate
     * @request POST:/v1/programs_section/
     * @secure
     */
    v1ProgramsSectionCreate: (data: ProgramSection, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/v1/programs_section/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Получение программного раздела
     *
     * @tags Программное обеспечение
     * @name V1ProgramsSectionRead
     * @request GET:/v1/programs_section/{id}/
     * @secure
     */
    v1ProgramsSectionRead: (id: string, params: RequestParams = {}) =>
      this.request<ProgramSection, void>({
        path: `/v1/programs_section/${id}/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Удаление программного раздела
     *
     * @tags Программное обеспечение
     * @name V1ProgramsSectionDelete
     * @request DELETE:/v1/programs_section/{id}/
     * @secure
     */
    v1ProgramsSectionDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/v1/programs_section/${id}/`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Получение списка версий для программы
     *
     * @tags Программное обеспечение
     * @name V1ProgramsVersionList
     * @request GET:/v1/programs_version/
     * @secure
     */
    v1ProgramsVersionList: (
      query?: {
        /** Метка раздела, используется для фильтрации */
        label?: string;
        /** Актуальность программы, используется для фильтрации */
        actual?: boolean;
        /** Идентификатор программной секции */
        section_id?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<ProgramVersion[], void>({
        path: `/v1/programs_version/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Скачивание файла с программой
     *
     * @tags Программное обеспечение
     * @name V1ProgramsVersionDownloadFileRead
     * @request GET:/v1/programs_version/download_file/{id}/
     * @secure
     */
    v1ProgramsVersionDownloadFileRead: (id: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/v1/programs_version/download_file/${id}/`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Создание новой версии программы
     *
     * @tags Программное обеспечение
     * @name V1ProgramsVersionUploadFileCreate
     * @request POST:/v1/programs_version/upload_file
     * @secure
     */
    v1ProgramsVersionUploadFileCreate: (
      data: {
        /** @format binary */
        data_file: File;
        /** @minLength 1 */
        version: string;
        /** @minLength 1 */
        label: string;
        /** @minLength 1 */
        changelog?: string;
        actual?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/v1/programs_version/upload_file`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * @description Получение раздела
     *
     * @tags Программное обеспечение
     * @name V1ProgramsVersionRead
     * @request GET:/v1/programs_version/{id}/
     * @secure
     */
    v1ProgramsVersionRead: (id: string, params: RequestParams = {}) =>
      this.request<ProgramSection, void>({
        path: `/v1/programs_version/${id}/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Частичное обновление информации о программах
     *
     * @tags Программное обеспечение
     * @name V1ProgramsVersionPartialUpdate
     * @request PATCH:/v1/programs_version/{id}/
     * @secure
     */
    v1ProgramsVersionPartialUpdate: (id: string, data: ProgramVersionSerializerPartial, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/v1/programs_version/${id}/`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Удаление раздела
     *
     * @tags Программное обеспечение
     * @name V1ProgramsVersionDelete
     * @request DELETE:/v1/programs_version/{id}/
     * @secure
     */
    v1ProgramsVersionDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/v1/programs_version/${id}/`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Получение RR данных сессии - расчетные
     *
     * @tags Данные: RR интервалы
     * @name V1RrCalcSignalsRead
     * @request GET:/v1/rr_calc_signals/{session_id}/{sensor_id}/
     * @secure
     */
    v1RrCalcSignalsRead: (sessionId: string, sensorId: string, params: RequestParams = {}) =>
      this.request<RRDataMeasureCalc[], void>({
        path: `/v1/rr_calc_signals/${sessionId}/${sensorId}/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Удаление расчетных RR данных
     *
     * @tags Данные: RR интервалы
     * @name V1RrCalcSignalsDelete
     * @request DELETE:/v1/rr_calc_signals/{session_id}/{sensor_id}/
     * @secure
     */
    v1RrCalcSignalsDelete: (sessionId: string, sensorId: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/v1/rr_calc_signals/${sessionId}/${sensorId}/`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Получение RR данных сессии - фильтрованные
     *
     * @tags Данные: RR интервалы
     * @name V1RrFilterSignalsRead
     * @request GET:/v1/rr_filter_signals/{session_id}/{sensor_id}/
     * @secure
     */
    v1RrFilterSignalsRead: (sessionId: string, sensorId: string, params: RequestParams = {}) =>
      this.request<RRDataMeasureCalc[], void>({
        path: `/v1/rr_filter_signals/${sessionId}/${sensorId}/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Удаление фильтрованных RR данных
     *
     * @tags Данные: RR интервалы
     * @name V1RrFilterSignalsDelete
     * @request DELETE:/v1/rr_filter_signals/{session_id}/{sensor_id}/
     * @secure
     */
    v1RrFilterSignalsDelete: (sessionId: string, sensorId: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/v1/rr_filter_signals/${sessionId}/${sensorId}/`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Обработка блока измерения RR интервалов
     *
     * @tags Данные: RR интервалы
     * @name V1RrSignalsCreate
     * @request POST:/v1/rr_signals/
     * @secure
     */
    v1RrSignalsCreate: (data: RRBlock, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/v1/rr_signals/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Получение RR данных сессии - блочные с датчика ( исходные)
     *
     * @tags Данные: RR интервалы
     * @name V1RrSignalsRead
     * @request GET:/v1/rr_signals/{session_id}/{sensor_id}/
     * @secure
     */
    v1RrSignalsRead: (sessionId: string, sensorId: string, params: RequestParams = {}) =>
      this.request<RRDataMeasure[], void>({
        path: `/v1/rr_signals/${sessionId}/${sensorId}/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Удаление исходных данных RR данных
     *
     * @tags Данные: RR интервалы
     * @name V1RrSignalsDelete
     * @request DELETE:/v1/rr_signals/{session_id}/{sensor_id}/
     * @secure
     */
    v1RrSignalsDelete: (sessionId: string, sensorId: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/v1/rr_signals/${sessionId}/${sensorId}/`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Получение всего списка сенсоров
     *
     * @tags Сенсоры
     * @name V1SensorsList
     * @request GET:/v1/sensors/
     * @secure
     */
    v1SensorsList: (params: RequestParams = {}) =>
      this.request<Sensor[], void>({
        path: `/v1/sensors/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Создание/получение нового сенсора для носителя
     *
     * @tags Сенсоры
     * @name V1SensorsCreate
     * @request POST:/v1/sensors/
     * @secure
     */
    v1SensorsCreate: (data: Sensor, params: RequestParams = {}) =>
      this.request<Sensor[], void>({
        path: `/v1/sensors/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Получение расширенного полного списка сенсоров
     *
     * @tags Сенсоры
     * @name V1SensorsListExtra
     * @request GET:/v1/sensors/list_extra/
     * @secure
     */
    v1SensorsListExtra: (params: RequestParams = {}) =>
      this.request<SensorExtra[], void>({
        path: `/v1/sensors/list_extra/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Удаление данных сессии
     *
     * @tags Сессии
     * @name V1SessionsDeleteDelete
     * @request DELETE:/v1/sessions/delete/{session_id}/
     * @secure
     */
    v1SessionsDeleteDelete: (sessionId: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/v1/sessions/delete/${sessionId}/`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Скачивание файла со стрессами размеченная экспертом в формате Excel для Ring
     *
     * @tags Сессии
     * @name V1SessionsDownloadExpertStressXlsxRingRead
     * @request GET:/v1/sessions/download_expert_stress_xlsx_ring/{session_id}/
     * @secure
     */
    v1SessionsDownloadExpertStressXlsxRingRead: (
      sessionId: string,
      query?: {
        /** Смещение временной зоны клиента относительно UTC в секундах */
        timezone_offset?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/v1/sessions/download_expert_stress_xlsx_ring/${sessionId}/`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Скачивание файла с измерениями сессии, полученный с телефона
     *
     * @tags Сессии
     * @name V1SessionsDownloadFileRead
     * @request GET:/v1/sessions/download_file/{session_id}/
     * @secure
     */
    v1SessionsDownloadFileRead: (sessionId: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/v1/sessions/download_file/${sessionId}/`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Скачивание файла с исходным спектром сессии - в формате CSV
     *
     * @tags Сессии
     * @name V1SessionsDownloadMeasureDataRawSpectrRead
     * @request GET:/v1/sessions/download_measure_data_raw_spectr/{session_id}/{filtered}/
     * @secure
     */
    v1SessionsDownloadMeasureDataRawSpectrRead: (
      sessionId: string,
      filtered: string,
      query?: {
        /** Смещение временной зоны клиента относительно UTC в секундах */
        timezone_offset?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/v1/sessions/download_measure_data_raw_spectr/${sessionId}/${filtered}/`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Скачивание файла с исходным спектром сессии - в формате Excel файл
     *
     * @tags Сессии
     * @name V1SessionsDownloadMeasureDataRawSpectrXlsxRead
     * @request GET:/v1/sessions/download_measure_data_raw_spectr_xlsx/{session_id}/{filtered}/
     * @secure
     */
    v1SessionsDownloadMeasureDataRawSpectrXlsxRead: (
      sessionId: string,
      filtered: string,
      query?: {
        /** Смещение временной зоны клиента относительно UTC в секундах */
        timezone_offset?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/v1/sessions/download_measure_data_raw_spectr_xlsx/${sessionId}/${filtered}/`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Скачивание файла с измерениями сессии - в формате Excel файл
     *
     * @tags Сессии
     * @name V1SessionsDownloadMeasureDataXlsxRead
     * @request GET:/v1/sessions/download_measure_data_xlsx/{session_id}/{filtered}/
     * @secure
     */
    v1SessionsDownloadMeasureDataXlsxRead: (
      sessionId: string,
      filtered: string,
      query?: {
        /** Смещение временной зоны клиента относительно UTC в секундах */
        timezone_offset?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/v1/sessions/download_measure_data_xlsx/${sessionId}/${filtered}/`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Скачивание файла с измерениями сессии - в формате Excel файл для Ring
     *
     * @tags Сессии
     * @name V1SessionsDownloadMeasureDataXlsxRingRead
     * @request GET:/v1/sessions/download_measure_data_xlsx_ring/{session_id}/
     * @secure
     */
    v1SessionsDownloadMeasureDataXlsxRingRead: (
      sessionId: string,
      query?: {
        /** Смещение временной зоны клиента относительно UTC в секундах */
        timezone_offset?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/v1/sessions/download_measure_data_xlsx_ring/${sessionId}/`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Скачивание файла с измерениями сессии - в формате ZIP
     *
     * @tags Скачивание данных
     * @name V1SessionsDownloadMeasureDataZipRead
     * @request GET:/v1/sessions/download_measure_data_zip/{session_id}/{filtered}/
     * @secure
     */
    v1SessionsDownloadMeasureDataZipRead: (
      sessionId: string,
      filtered: string,
      query?: {
        /** Смещение временной зоны клиента относительно UTC в секундах */
        timezone_offset?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/v1/sessions/download_measure_data_zip/${sessionId}/${filtered}/`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Скачивание файла в формате trainset для разметки
     *
     * @tags Скачивание данных
     * @name V1SessionsDownloadTrainsetExportRead
     * @request GET:/v1/sessions/download_trainset_export/{session_id}/
     * @secure
     */
    v1SessionsDownloadTrainsetExportRead: (
      sessionId: string,
      query?: {
        /** Список маркеров */
        markers?: string[];
        /** Смещение временной зоны клиента относительно UTC в секундах */
        timezone_offset?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/v1/sessions/download_trainset_export/${sessionId}/`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Скачивание файла с экстрасистолами для Ковальчука для разметки
     *
     * @tags Скачивание данных
     * @name V1SessionsDownloadTrainsetExtrasystoleRead
     * @request GET:/v1/sessions/download_trainset_extrasystole/{session_id}/
     * @secure
     */
    v1SessionsDownloadTrainsetExtrasystoleRead: (sessionId: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/v1/sessions/download_trainset_extrasystole/${sessionId}/`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Закрытие сессии - обновляем время закрытия и статус
     *
     * @tags Сессии
     * @name V1SessionsFinishCreate
     * @request POST:/v1/sessions/finish
     * @secure
     */
    v1SessionsFinishCreate: (data: SessionFinish, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/v1/sessions/finish`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Получение информации о конкретной сессии
     *
     * @tags Сессии
     * @name V1SessionsInfoRead
     * @request GET:/v1/sessions/info/{session_id}/
     * @secure
     */
    v1SessionsInfoRead: (sessionId: string, params: RequestParams = {}) =>
      this.request<SessionInfo, void>({
        path: `/v1/sessions/info/${sessionId}/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Расширенное получение информации о конкретной сессии
     *
     * @tags Сессии
     * @name V1SessionsInfoExtraRead
     * @request GET:/v1/sessions/info_extra/{session_id}/
     * @secure
     */
    v1SessionsInfoExtraRead: (sessionId: string, params: RequestParams = {}) =>
      this.request<SessionInfoExtra, void>({
        path: `/v1/sessions/info_extra/${sessionId}/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Получение списка измерительных сессий
     *
     * @tags Сессии
     * @name V1SessionsListList
     * @request GET:/v1/sessions/list
     * @secure
     */
    v1SessionsListList: (
      query?: {
        /** Статус сессии */
        status?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<Session[], void>({
        path: `/v1/sessions/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Получение расширенного списка измерительных сессий
     *
     * @tags Сессии
     * @name V1SessionsListExtraList
     * @request GET:/v1/sessions/list_extra
     * @secure
     */
    v1SessionsListExtraList: (
      query?: {
        /** Имя объекта */
        nick?: string;
        /** Пол */
        gender?: string;
        /** Список контекстов */
        contexts?: string[];
        /** Список маркеров */
        markers?: string[];
        /** Статус записи */
        status?: number;
        /** Начальный возраст носителя */
        old_start?: number;
        /** Конечный возраст носителя */
        old_end?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<SessionFrontend[], void>({
        path: `/v1/sessions/list_extra`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Получение информации о выделенном диапазоне
     *
     * @tags Сессии
     * @name V1SessionsRangeRead
     * @request GET:/v1/sessions/range/{session_id}/
     * @secure
     */
    v1SessionsRangeRead: (
      sessionId: string,
      query?: {
        /**
         * Время начала выделенного фрагмента
         * @format date-time
         */
        dt_start?: string;
        /**
         * Время окончания выделенного фрагмента
         * @format date-time
         */
        dt_end?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SessionRangeState, void>({
        path: `/v1/sessions/range/${sessionId}/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Запуск перерасчета данных сессии
     *
     * @tags Сессии
     * @name V1SessionsRecalculateRead
     * @request GET:/v1/sessions/recalculate/{session_id}/
     * @secure
     */
    v1SessionsRecalculateRead: (sessionId: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/v1/sessions/recalculate/${sessionId}/`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Получение сенсоров сессии
     *
     * @tags Сессии
     * @name V1SessionsSensorsRead
     * @request GET:/v1/sessions/sensors/{session_id}/
     * @secure
     */
    v1SessionsSensorsRead: (sessionId: string, params: RequestParams = {}) =>
      this.request<SessionSignal[], void>({
        path: `/v1/sessions/sensors/${sessionId}/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Расширенно получение сенсоров сессии - фронтенд
     *
     * @tags Сессии
     * @name V1SessionsSensorsExtraRead
     * @request GET:/v1/sessions/sensors_extra/{session_id}/
     * @secure
     */
    v1SessionsSensorsExtraRead: (sessionId: string, params: RequestParams = {}) =>
      this.request<SessionSignalExtra[], void>({
        path: `/v1/sessions/sensors_extra/${sessionId}/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Создание сессии измерения
     *
     * @tags Сессии
     * @name V1SessionsStartCreate
     * @request POST:/v1/sessions/start
     * @secure
     */
    v1SessionsStartCreate: (data: SessionCreate, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/v1/sessions/start`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Получение информации о статусе конкретной сессии
     *
     * @tags Сессии
     * @name V1SessionsStateRead
     * @request GET:/v1/sessions/state/{session_id}/
     * @secure
     */
    v1SessionsStateRead: (sessionId: string, params: RequestParams = {}) =>
      this.request<SessionState, void>({
        path: `/v1/sessions/state/${sessionId}/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Обновление параметров сессии (тех кого разрешено менять)
     *
     * @tags Сессии
     * @name V1SessionsUpdateCreate
     * @request POST:/v1/sessions/update
     * @secure
     */
    v1SessionsUpdateCreate: (data: SessionUpdate, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/v1/sessions/update`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Прикрепление к сессии файла с измерением
     *
     * @tags Сессии
     * @name V1SessionsUploadFileCreate
     * @request POST:/v1/sessions/upload_file
     * @secure
     */
    v1SessionsUploadFileCreate: (
      data: {
        session: number;
        /** @format binary */
        data_file: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/v1/sessions/upload_file`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * @description Получение спектральных данных
     *
     * @tags Данные: Спектр
     * @name V1SpectrSignalsRead
     * @request GET:/v1/spectr_signals/{session_id}/{sensor_id}/{filtered}/
     * @secure
     */
    v1SpectrSignalsRead: (
      sessionId: string,
      sensorId: string,
      filtered: string,
      query?: {
        /** Флаг, для фильтрации по точкам, по которым был рассчитан стресс */
        stress?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<SpectrData[], void>({
        path: `/v1/spectr_signals/${sessionId}/${sensorId}/${filtered}/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
}

import React, {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5xy from '@amcharts/amcharts5/xy';

import dayjs from 'dayjs';
import {SessionSpectrTimeGraph, SessionTimeGraphProps} from './types';
import { SpectrData } from '../../../models/types';
import { appTimeFormatWithSecond} from "../../../consts";
import {findClosestDateIndex} from "../../../models/helper";
import {MARKER_ACUTE_STRESS} from "../../../core/constants/markers";
import SPECTR_INTERVAL from "../../../core/constants/time_limits";

const SpectrTimeGraph: React.FC<SessionTimeGraphProps<SessionSpectrTimeGraph>> = ({
  timeData:{
    queryFn,
    markers,
    markerIdsVisible,
    minX,
    maxX,
    minY,
    maxY,
  },
  chartName,
  graphRangeX,
  setGraphRangeX,
}) => {

  const [
    markerRange, setMarkerRange
  ]  = useState<Array<am5.DataItem<am5xy.IDateAxisDataItem>>>([])

  const chartRef = useRef<am5xy.XYChart | null>(null);
  const seriesRefTP = useRef<am5xy.LineSeries | null>(null);
  const seriesRefLFHF = useRef<am5xy.LineSeries | null>(null);
  const xAxisRef = useRef<am5xy.DateAxis<am5xy.AxisRenderer> | null>(null);

  const [spectrCount, setSpectrCount]= useState<number>();
  const [cursorDT, setCursorDT] = useState<Date>();
  const [cursorLF, setCursorLF] = useState<number>();
  const [cursorHF, setCursorHF] = useState<number>();
  const [cursorTP, setCursorTP] = useState<number>();
  const [cursorLFHF, setCursorLFHF] = useState<number>();

  // чтобы получить на графике мгновенные значения LF, HF - мы их запомним и будем искать самостоятельно
  const dataX :Date[] = []
  const dataLF :number[] = []
  const dataHF :number[] = []
  const dataTP :number[] = []
  const dataLFHF :number[] = []

  useLayoutEffect(() => {
    // создаем основу графика
    const root = am5.Root.new(chartName);

    // прячем лого
    root._logo?.dispose();

    // навешиваем тему
    root.setThemes([am5themes_Animated.new(root)]);

    // создаем отрисовку
    const chart = root.container.children.push(am5xy.XYChart.new(root, {}));

    chartRef.current = chart

    // создадим рамку на графике
    const background = chart.plotContainer.get("background");
    if (background){
      background.setAll({
        strokeWidth: 2,
        strokeOpacity: 1,
        stroke: am5.color('#000'),
        fill: am5.color(0xffffd1),
        fillOpacity: 1.0,
      })
    }

    // отключим кнопку ZOOM
    chart.zoomOutButton.set("forceHidden", true);

    // создаем оси
    const xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        min: minX.getTime(),
        max: maxX ? maxX.getTime() : undefined,
        strictMinMax: true,
        baseInterval: {
          timeUnit: 'millisecond',
          count: 1,
        },
        renderer: am5xy.AxisRendererX.new(root, {}),
      }),
    );
    xAxisRef.current = xAxis;

    const yAxisTP = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: minY,
        max: maxY,
        renderer: am5xy.AxisRendererY.new(root, {
          // отпция застравляет отрисовывать ось Y внутри графика
          inside: true,
          brightness: 1,
        }),
        // фиксим ошибку, что внутри метки не видны при полной непрозрачности
        layer:1,
      }),
    );

    yAxisTP.get('renderer').labels.template.setAll({
      fill: am5.color(0x000000),
      dy:10,
      // opacity: 1.0,
      // fillOpacity: 1.0,
      // brightness: 1,
    });

    const yAxisLFHF = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        // min: timeData.minY,
        // max: timeData.maxY,
        renderer: am5xy.AxisRendererY.new(root, {
          // отпция застравляет отрисовывать ось Y внутри графика
          inside: true,
          // отображаем ось слева
          opposite: true,
        }),
        // фиксим ошибку, что внутри метки не видны при полной непрозрачности
        layer:1,
      }),
    );
    yAxisLFHF.get('renderer').labels.template.setAll({
      fill: am5.color(0xff0000),
      // fontSize: '1.5em',
      dy:10,
      // opacity: 1.0
    });

    // создаем серии
    const seriesTP = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: 'Series',
        xAxis,
        yAxis: yAxisTP,
        valueYField: 'value',
        valueXField: 'date',
        stroke: am5.color(0x000000),
      }),
    );
    const seriesLFHF = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: 'Series',
        xAxis,
        yAxis: yAxisLFHF,
        valueYField: 'value',
        valueXField: 'date',
        stroke: am5.color(0xff0000),
      }),
    );

    // точки на графике
    seriesTP.bullets.push(() => {
      return am5.Bullet.new(root, {
        sprite: am5.Circle.new(root, {
          radius: 4,
          fill:  am5.color(0x000000),
        }),
      });
    });
    seriesLFHF.bullets.push(() => {
      return am5.Bullet.new(root, {
        sprite: am5.Circle.new(root, {
          radius: 4,
          fill:  am5.color(0xff0000),
        }),
      });
    });

    // создадим курсор
    const cursor = chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
      }),
    );

    // будем отслеживать положение курсора
    cursor.events.on("cursormoved", (ev) => {
      const x = ev.target.getPrivate("positionX");

      if (x) {
        const dateX = xAxis.positionToDate(xAxis.toAxisPosition(x));

        setCursorDT(dateX);

        // пробуем вывести LF, HF
        if (dataX) {
          const xIndex = findClosestDateIndex(dataX, dateX);
          if (xIndex>-1){
            // console.log('dateX',dateX, 'xIndex', xIndex);
            setCursorLF(Number(dataLF?.at(xIndex)?.toFixed(2)))
            setCursorHF(Number(dataHF?.at(xIndex)?.toFixed(2)))
            setCursorTP(Number(dataTP?.at(xIndex)?.toFixed(2)));
            setCursorLFHF(Number(dataLFHF?.at(xIndex)?.toFixed(2)));
          }
        }
      }
    })

    seriesRefTP.current = seriesTP;
    seriesRefLFHF.current = seriesLFHF;

    return () => {
      root.dispose();
    };
  }, []);

  // useEffect(() => {
  //   seriesRef.current?.data.setAll([{
  //     date: new Date(2012, 1, 1).getTime(),
  //     value: 80,
  //   }, {
  //     date: new Date(2012, 1, 2).getTime(),
  //     value: 50,
  //   },
  //   ]);
  // });

  useEffect(() => {
    // стартуем обновлялку сигнала - раз в 5 секунд
    const updateData = async () => {
      try {
        // console.log('Update', chartName);
        const data = await queryFn();
        const spectrData = data.data as SpectrData[];
        const procTP: any[] = [];
        const procLFHF: any[] = [];

        dataX.length = 0;
        dataLF.length = 0;
        dataHF.length = 0;
        dataTP.length = 0;
        dataLFHF.length = 0;

        spectrData.forEach((item) => {
          const dt = am5.time
            .add(dayjs(item.dt).toDate(), 'millisecond', 1)
            .getTime();
          // делаем онлайн расчет
          procTP.push({
            date: dt,
            value: item.vlf + item.lf + item.hf,
          });
          procLFHF.push({
            date: dt,
            value: item.lf / item.hf,
          });
          // для отображения временных данных
          dataX.push(dayjs(item.dt).toDate())
          dataLF.push(item.lf)
          dataHF.push(item.hf)
          dataTP.push(item.vlf + item.lf + item.hf)
          dataLFHF.push(item.lf / item.hf)
        });

        seriesRefTP.current?.data.setAll(procTP);
        seriesRefLFHF.current?.data.setAll(procLFHF);
        setSpectrCount(procTP.length);

      } catch (e) {
        console.error(e);
      } finally {
        // setTimeout(() => {
        //   updateData();
        // }, 1000);
      }
    };

    updateData();
  }, []);

  /* Тут мы делаем переотрисовку маркеров */
  useEffect(() => {

    const newMarkerRange: Array<am5.DataItem<am5xy.IDateAxisDataItem>> = []

    // сначала удалим все, что навставляли
    markerRange.forEach((item) => {
      xAxisRef.current?.axisRanges.removeValue(item);
    })

    // потом вставим
    markers.forEach((item) => {
      if (markerIdsVisible.includes(item.marker_id)) {

        const markerItem = xAxisRef.current?.makeDataItem({});

        // для маркеров острого стресса мы делаем сдвиг
        let startDt = item.start_dt
        if (item.marker_id === MARKER_ACUTE_STRESS){
          console.log(startDt, dayjs(startDt).add(SPECTR_INTERVAL,'millisecond').toDate())
          startDt = dayjs(startDt).add(SPECTR_INTERVAL,'millisecond').toDate()
        }

        markerItem?.set(
          'value',
          am5.time.add(startDt, 'millisecond', 1).getTime(),
        );
        if (item.end_dt) {

          // для маркеров острого стресса мы делаем сдвиг
          let endDt = item.end_dt
          if (item.marker_id === MARKER_ACUTE_STRESS){
            endDt = dayjs(endDt).add(SPECTR_INTERVAL,'millisecond').toDate()
          }

          markerItem?.set(
            'endValue',
            am5.time.add(endDt, 'millisecond', 1).getTime(),
          );
        }
        if (markerItem) {
          const range = xAxisRef.current?.createAxisRange(markerItem);
          if (range) {
            newMarkerRange.push(range);
          }
          markerItem.get('axisFill')?.setAll({
            // fill: chartRef.current?.get('colors')?.getIndex(12),
            fill: am5.color(item.color),
            fillOpacity: 1,
            visible: true,
          });
        }
      }
    });

    setMarkerRange(newMarkerRange);

  }, [markerIdsVisible, markers])


  useEffect(() => {
    if (!setGraphRangeX) {
      if (graphRangeX) {
        xAxisRef.current?.setAll(graphRangeX);
      }
    }
  }, [graphRangeX]);

  return (
    <>
      <div>
        Name: <b>SPECTR</b>,
        points: <b>{spectrCount}</b>,
        Legends: <b>TP</b>, <span style={{color: "red"}}><b>LF/HF</b></span>,
        Cursor: Time=<b>{dayjs(cursorDT).format(appTimeFormatWithSecond)}</b>,
        TP=<b>{cursorTP}</b>, LF/HF=<span style={{color: "red"}}><b>{cursorLFHF}</b></span>,
        LF=<span style={{color: "green"}}><b>{cursorLF}</b></span>,
        HF=<span style={{color: "green"}}><b>{cursorHF}</b></span>.
      </div>
      <div id={chartName} style={{ width: '100%', height: '250px' }} />
    </>
  )

};

export default SpectrTimeGraph;

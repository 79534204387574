import {Col, ColorPicker, Modal, Row, Spin, Table} from "antd";
import React from "react";
import {useMutation, useQuery} from "@tanstack/react-query";
import {ColumnsType} from "antd/es/table";
import axios, {AxiosError} from "axios";
import {MarkerExtra, MarkerExtraUpdate} from "../../models/types";
import api from "../../http";


const MarkerList = () => {

  // тут проблемы с выбором цвета - поэтому менять будем в django админке

  const mutationUpdateMarker = useMutation(
    (data: MarkerExtraUpdate) => api.v1.v1MarkersPartialUpdate(data.id!, data)
  );

  const changeColorConfirm = (id: number, color: string) => {

    Modal.confirm({
      title: 'Вы уверены?',
      content: 'Вы действительно хотите изменить цвет события?',
      okText: 'Да',
      cancelText: 'Нет',
      onOk() {
        console.log('Элемент удалён');
      },
      onCancel() {
        console.log('Удаление отменено');
      },
    });
  };

  const {
    isLoading,
    isError,
    error,
    data: dataMarkers,
  } = useQuery(['MarkersListExtra'], () => api.v1.v1MarkersListExtra());

  const columns: ColumnsType<MarkerExtra> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 100,
    },
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      width: 300,
    },
    {
      title: 'Цвет',
      dataIndex: 'color',
      key: 'color',
      render: (_, record) => {
        return (
          <ColorPicker
            defaultValue={record.color}
            showText
            onChangeComplete={(newValue) => {
              changeColorConfirm(record.id!, newValue.toHexString())}
            }
          />
        )
      }
    },
  ]

  if (error && axios.isAxiosError(error)) {
    const err: AxiosError = error as AxiosError;
    return <>Произошла ошибка: {err.message}</>;
  }

  if (isLoading) {
    return <div style={{display: 'flex', justifyContent: 'center', marginTop: 50}}><Spin/></div>;
  }

  return (
    <Row>
      <Col span={24} style={{padding: 20}}>
        <h1>Список событий</h1>
        <p>Не меняйте часто цвета событий</p>
        <Table
          columns={columns}
          dataSource={dataMarkers?.data}
          bordered
          rowKey="id"
          pagination={{ pageSize: 20 }}
        />
      </Col>
    </Row>
  )
}

export default MarkerList;
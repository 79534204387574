import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import dayjs from "dayjs";

import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {SessionDataZoomGraph, SessionDataZoomProps, SessionTimeData, SessionTimeGraphProps} from './types';
import {RRDataMeasureCalc} from "../../../models/types";

const DataZoomGraph: React.FC<SessionDataZoomProps<SessionDataZoomGraph>> = ({
  timeData: {
    queryFn,
    minX,
    maxX,
    minY,
    maxY,
    markers,
    markerIdsVisible,
  },
  chartName,
  graphRangeX,
  setGraphRangeX,
  graphRangeDateX,
  setGraphRangeDateX
}) => {

  let cursorSelectRangeStartX: number;
  let cursorSelectRangeDateStartX: Date;
  let cursorSelectRangeEndX: number;
  let cursorSelectRangeDateEndX: Date;

  const [
    markerRange, setMarkerRange
  ]  = useState<Array<am5.DataItem<am5xy.IDateAxisDataItem>>>([])

  const chartRef = useRef<am5xy.XYChart | null>(null);
  const seriesRef = useRef<am5xy.LineSeries | null>(null);
  const xAxisRef = useRef<am5xy.DateAxis<am5xy.AxisRenderer> | null>(null);
  const selectRange = useRef<am5.DataItem<am5xy.IDateAxisDataItem> | null> (null);

  /*  Первичная инициализация графика с данными */
  useLayoutEffect(() => {
    // создаем основу графика
    const root = am5.Root.new(chartName);
    // прячем лого
    root._logo?.dispose();

    root.dateFormatter.set("dateFormat", "yyyy-MM-dd");

    // навешиваем тему
    root.setThemes([am5themes_Animated.new(root)]);
    //
    // создаем отрисовку
    const chart = root.container.children.push(
        am5xy.XYChart.new(root, {}),
    );

    chartRef.current = chart

    // создадим рамку на графике
    const background = chart.plotContainer.get("background");
    if (background){
      background.setAll({
        strokeWidth: 2,
        strokeOpacity: 1,
        stroke: am5.color('#000'),
        fill: am5.color(0xFFFFFF),
        fillOpacity: 0.1
      })
    }

    const tooltipXAxis = am5.Tooltip.new(root, {
    })

    // создаем оси
    const xAxis = chart.xAxes.push(
        am5xy.DateAxis.new(root, {
          min: minX.getTime(),
          max: maxX ? maxX.getTime() : undefined,
          strictMinMax: true,
          baseInterval: {
            timeUnit: 'millisecond',
            count: 1,
          },
          renderer: am5xy.AxisRendererX.new(root, {
          }),
          // выводим подсказку, где курсор, в виде даты
          tooltip: tooltipXAxis,

        }),
    );

    // метки мы просто так отключить не можем, пропадет место под них
    // делаем грязный хак - показываем пробелы вместо меток
    const dateFormats = xAxis.get("dateFormats");
    if (dateFormats){
      dateFormats.minute = " ";
      dateFormats.hour = " ";
    }

    // вывод нужного текста в метку
    tooltipXAxis.label.adapters.add("text", (text, target) => {
      return text?.split(' ')[0];
    });

    // выставим задний фон у tooltip
    tooltipXAxis.get("background")?.setAll({
      fill: am5.color(0xFFFFFF)
    });

    // tooltipXAxis.renderer.label.template
    xAxisRef.current = xAxis;

    const yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          min: minY,
          max: maxY,
          strictMinMax: true,
          renderer: am5xy.AxisRendererY.new(root, {
            // метки по Y будут внутри оси
            inside: true,
          }),
        }),
    );
    yAxis.hide()

    // создадим курсор
    const cursor = chart.set(
        'cursor',
        am5xy.XYCursor.new(root, {
          behavior: 'selectX',
        }),
    );
    // отключаем горизонтальный маркер
    cursor.lineY.setAll({
      visible: false,
    })
    cursor.lineX.setAll({
      stroke: am5.color(0x9999ff),
      strokeWidth: 2,
    })
    cursor.selection.setAll({
      fill: am5.color(0x9999ff),
      fillOpacity: 0.5
    });

    // обрабатываем начало выделения
    cursor.events.on('selectstarted', () => {
      const posX = cursor.getPrivate('positionX');
      if (posX) {
        // чистим все выделения
        if (selectRange.current){
          xAxis.axisRanges.removeValue(selectRange.current)
          selectRange.current = null
        }

        cursorSelectRangeStartX = xAxis.toAxisPosition(posX)
        cursorSelectRangeDateStartX = xAxis.positionToDate(cursorSelectRangeStartX)
      }
    });

    // обрабатываем конец выделения и выставляем зум
    cursor.events.on('selectended', () => {
      const posX = cursor.getPrivate('positionX');
      if (posX) {
        cursorSelectRangeEndX = xAxis.toAxisPosition(posX)
        cursorSelectRangeDateEndX = xAxis.positionToDate(cursorSelectRangeEndX)

        // в случае, если выделение было справо - налево, свапим переменные
        if (cursorSelectRangeEndX < cursorSelectRangeStartX){
          [cursorSelectRangeStartX, cursorSelectRangeEndX] = [cursorSelectRangeEndX, cursorSelectRangeStartX]
        }
        if (cursorSelectRangeDateEndX < cursorSelectRangeDateStartX) {
          [cursorSelectRangeDateStartX, cursorSelectRangeDateEndX] = [cursorSelectRangeDateEndX, cursorSelectRangeDateStartX]
        }

        const selectRangeItem = xAxis.makeDataItem({
          value: am5.time.add(cursorSelectRangeDateStartX, 'millisecond', 1).getTime(),
          endValue: am5.time.add(cursorSelectRangeDateEndX, 'millisecond', 1).getTime()
        })

        selectRange.current = xAxis.createAxisRange(selectRangeItem);

        selectRangeItem.get('axisFill')?.setAll({
          fill: am5.color(0x9999ff),
          fillOpacity: 0.5,
          visible: true,
        });

        // скидываем инфу о зумминге осей
        if (setGraphRangeX) {
            setGraphRangeX({ start: cursorSelectRangeStartX, end: cursorSelectRangeEndX });
        }
        if (setGraphRangeDateX){
          setGraphRangeDateX( {start: cursorSelectRangeDateStartX, end: cursorSelectRangeDateEndX})
        }
      }
    });

    // создаем серии
    const series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: 'Series',
          xAxis,
          yAxis,
          valueYField: 'value',
          valueXField: 'date',
          fill: am5.color(0x5e5e77),
          stroke: am5.color(0x1a1aff),
        }),
    );
    series.fills.template.setAll({
      fillOpacity: 0.1,
      visible: true,
    });

    seriesRef.current = series;

    return () => {
      root.dispose();
    };
  }, []);

  useEffect(() => {
    const updateData = async () => {
      try {
        // console.log('Update', chartName);
        const data = await queryFn();
        const rrCalcData = data.data as RRDataMeasureCalc[];
        const procRRCalc: any[] = [];
        rrCalcData.forEach((item) => {
          procRRCalc.push({
            date: am5.time
                .add(dayjs(item.dt).toDate(), 'millisecond', 1)
                .getTime(),
            // date: item.id,
            value: item.value,
          });
          // console.log(am5.time.add(moment(item.dt).toDate(), 'millisecond', 1).getTime());
        });

        seriesRef.current?.data.setAll(procRRCalc);

        // console.log('rrData', rrCalcData[0]);
      } catch (e) {
        console.error(e);
      } finally {
        // setTimeout(() => {
        //   updateData();
        // }, 1000);
      }
    };

    updateData();
  }, []);

  /* Тут мы делаем переотрисовку маркеров */
  useEffect(() => {

    const newMarkerRange: Array<am5.DataItem<am5xy.IDateAxisDataItem>> = []

    // сначала удалим все, что навставляли
    markerRange.forEach((item) => {
      xAxisRef.current?.axisRanges.removeValue(item);
    })

    // потом вставим
    markers.forEach((item) => {
      if (markerIdsVisible.includes(item.marker_id)) {

        const markerItem = xAxisRef.current?.makeDataItem({});
        markerItem?.set(
          'value',
          am5.time.add(item.start_dt, 'millisecond', 1).getTime(),
        );
        if (item.end_dt) {
          markerItem?.set(
            'endValue',
            am5.time.add(item.end_dt, 'millisecond', 1).getTime(),
          );
        }
        if (markerItem) {
          const range = xAxisRef.current?.createAxisRange(markerItem);
          if (range) {
            newMarkerRange.push(range);
          }
          markerItem.get('axisFill')?.setAll({
            // fill: chartRef.current?.get('colors')?.getIndex(12),
            fill: am5.color(item.color),
            fillOpacity: 1,
            visible: true,
          });
        }
      }
    });

    setMarkerRange(newMarkerRange);

  }, [markerIdsVisible, markers])

  return <div id={chartName} style={{ width: '100%', height: '80px' }} />;
};

export default DataZoomGraph;
